.ModelWindowBG{
    position: fixed;
    left:0px;
    top:0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    z-index: 11; 
    /* display: none; */
    transition: .15s;
    opacity: 0;

}

.ModelWindow {
    position: absolute;
    /* margin: auto; */
    top:70px;
    bottom:70px;
    left:25%;
    right:25%;
    /* transform: translateX(-250px); */
    /* padding: 0px 10px; */
    /* height:800px; */
    /* min-width:650px; */
    /* max-width:650px; */
    width:650px;
    /* background-color: rgb(3, 245, 44); */
    /* background-color: rgba(0, 0, 0, .5); */
    background: linear-gradient(to top right, hsla(200, 4%, 70%, .5) ,hsla(200, 10%, 50%, .5));

    /* background: linear-gradient(rgb(233, 229, 204), rgb(238, 236, 204)); */

    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3);
    /* font-family:Courier, monospace; */
    /* font-family:Arial, Helvetica, sans-serif ; */
    border-style: none;
    border-radius: 4px;
    font-size: 18px;
    transition: .15s;
    transform-origin: 50% 50%;
    transform: translateX(500px);

    font-family: 'Open Sans Condensed', sans-serif;
    z-index: 12; 
    /* padding: 20px; */
    box-sizing: border-box;
    backdrop-filter: blur(6px);
    background: linear-gradient(to top, hsla(200, 4%, 85%, .6) ,hsla(200, 4%, 100%, .6));
    background: linear-gradient(to top right, hsla(200, 20%, 100%, .5) ,hsla(200, 40%, 80%, .5));




}

.customPanelDiv{
    position: absolute;
    /* background-color: greenyellow; */
    top:70px;
    bottom:6px;
    /* height: 100%; */
    width: 100%;
    /* margin:20px; */
    /* padding-top:30px;
    padding-bottom: 30px; */
    /* max-height: 300px; */
    box-sizing: border-box;
    overflow-y:auto;
    /* padding: 20px; */


}

.windowTitle {
    margin:auto;
    width:300px;
    font-size: 32px;
    color:white;
}