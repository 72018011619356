.UserPanelDiv{
    width:100%;
    height:100%;
    position: relative;
    margin:12px 0px;
    text-align: center;

}

.UserItem {
    position: fixed;
    /* text-align: right; */
    right:10px;
    top:4px;
    height: 32px;
    width: 32px;
    /* max-width: 300px; 
    max-height: 300px; */
    border-radius:100%;
    transition: .2s;
    border-style: none;
    overflow: hidden;
    display:flex;
    cursor:pointer;

}
.UserItem:hover {
    transition: .2s;
    box-shadow: 0px 0px 8px 3px rgba(255,255,255,.8);
    box-shadow: 0px 0px 3px 1px rgba(255,255,255,.9);
    /* border-style: solid; */


}


#UserIcon_noImage {
    color:white;

    width:100%;
    height:100%;
    position: relative;
    margin:auto;
    /* left:-5px; */
    /* object-fit: contain; */
    object-fit: cover;

    /* right:10px;
    top:4px;
    height: 32px;
    width: 32px;

    border-radius:100%;
    transition: .2s;
    border-style: none; */
    font-size: 70px; 
    padding:10px 0px 0px 0px;

}


#UserIcon_noImage_small {
    width:100%;
    height:100%;
    position: relative;
    margin:auto;
    /* left:-5px; */
    /* object-fit: contain; */
    object-fit: cover;

    /* right:10px;
    top:4px;
    height: 32px;
    width: 32px;

    border-radius:100%;
    transition: .2s;
    border-style: none; */
    /* font-size: 70px;  */
    /* text-align: center; */
    padding:4px 0px 0px 0px;
    background-color: rgba(255,255,255,.2);

    font-size: 30px;
    color: rgba(255,255,255,.5);
}
#UserIcon_noImage_small:hover {
    transition: .2s;
    color: rgba(255, 255, 255, 1)}


#UserIcon {
    width:100%;
    height:100%;
    position: relative;
    margin:auto;
    /* left:-5px; */
    /* object-fit: contain; */
    object-fit: cover;
    background-color: rgba(255,255,255,.2);
    /* color: red; */

    /* right:10px;
    top:4px;
    height: 32px;
    width: 32px;

    border-radius:100%;
    transition: .2s;
    border-style: none; */

}



.nameText{
    color:black;
    font-size: 26px;
    text-align: center;
    height:40px;
    font-family: 'Open Sans Condensed', sans-serif;

}
.emailText{
    color:black;
    font-size: 20px;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    padding:10px  0px 0px 0px;
}
.storageText{
    color:black;
    font-size: 16px;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    padding:0px  0px 20px 0px;
}



.UserPanel{
    /* position: fixed; */
    /* background-color: white; */
    right:10px;
    top:48px;
    /* height: 200px; */
    width: 180px;
    /* max-width: 300px; 
    max-height: 300px; */
    border-radius:4px;
    /* transition: .2s; */
    /* border-style: none; */
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, .3);
    padding:12px;
    /* background-color: rgba(255, 255, 255, .8 ); */


}

.UserPanelBG{
    position:fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .4 );
}
.ArrowUp {
    position:fixed;
    top:42px;
    right:12px;
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    
    border-bottom: 15px solid white;
  }
/* .UserPanel:before {
    position: fixed;
    top:-10px;

    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid black;
  } */

.ChangeUserDiv{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size:18px;
    font-weight: bold;
    /* color:black; */
    color: hsl(200, 40%, 50%);
    cursor:pointer;

}
.ChangeUserDiv:hover{
    color: hsl(200, 40%, 10%);

}

.UserPanelIcon{
    margin:auto;
    height:80px;
    width:80px;
    background-color: grey;
    border-radius: 100%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .4);
    border-color:white;
    border-style: solid;
    overflow:hidden;
    cursor:pointer;


}
.UserPanelIcon:hover{
    /* border-color: rgba(0, 0, 0, .0); */
    /* border-style: solid; */
    /* box-shadow: 2px 4px 18px 0px rgba(0, 0, 0, .6); */
    box-shadow: 0px 2px 14px 2px rgba(151, 152, 248, 0.623);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, .6);


}
/* .UserPanelIcon:active{
    border-color: red;
    border-style: solid;
} */
