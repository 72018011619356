.TimelineCard {
    position: absolute;
    padding: 12px 10px 10px 10px;
    box-sizing: border-box;
    margin-top: 3px;
    height:58px;
    width:330px;
    background-color: rgb(238, 225, 169);
    /* background-color: orange; */
    /* background-color: rgb(224, 215, 160); */
    /* background: linear-gradient(rgb(233, 229, 204), rgb(238, 236, 204)); */

    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, .3);
    /* font-family:Courier, monospace; */
    font-family:Arial, Helvetica, sans-serif ;
    border-style: none;
    border-radius: 8px;
    /* border-color: white; */
    border-color: rgba(255, 255, 255, 0.8);
    /* border-color: rgba(10, 200, 255, 0.8); */
    border-width: 6px;


    font-size: 18px;

    user-select: none; /* Non-prefixed version, currently*/

    cursor: pointer;

}



.TimelineCard:hover{
    /* transition: .1s; */
    background-color: rgb(245, 234, 184);
    /* box-shadow: -1px 4px 4px 0px rgba(0, 0, 0, .2); */
    /* transition-property: "transform"  .01s; */
    /* transition-property: "box-shadow"  .01s; */
    user-select: none; /* Non-prefixed version, currently*/


}


.TimelineCardStartHandle{
    position: absolute;
    top: 0px;
    left: 0px;
    height:100%;
    width:20px;
    background-color:orange;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    opacity:.3;
    /* transform: rotate 45; */
    /* transform: rotate(135deg); */
    cursor: ew-resize;
}
.TimelineCardStartHandle:hover{
    opacity:1;

}
.TimelineCardEndHandle{
    position: absolute;
    top: 0px;
    right: 0px;
    height:100%;
    width:20px;
    background-color:orange;

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity:.3;
    /* transform: rotate 45; */
    /* transform: rotate(135deg); */
    cursor: ew-resize;
}
.TimelineCardEndHandle:hover{
    opacity:1;

}



