
.ThreadPickerDivA {
    position: absolute;
    width:150px;
}
.ThreadPickerDiv {
    position: relative;
    z-index: 10;
    /* color: white; */
    color: rgb(240, 240, 240);
    opacity: .8;
    font-size: 28px;
    width:100px;
    /* transition: .2s; */
    cursor:pointer;
    margin:auto;
    /* pointer-events: all; */

}

.ThreadPickerDiv:hover {
    opacity: 1;
    color:white; 
}

.ThreadPicker {
    margin:auto;
}
.ThreadColourStrip{
    position: relative;
    height:5px;
    width:100%;
    margin:5px 0px;
    background-color: red;
    border-radius: 5px; 
    /* border-radius: 1px; 
    border: 3px dashed red; */

}

.ThreadListDiv {
    z-index: 10000;
    position: relative;
    /* height:10px; */
    height:1px;
    height: auto;
    left:-40px;
    width:230px;
    /* border-style: solid; */
    border-radius: 6px;
    border-color: white;
    /* padding:6px; */
    background-color: hsla(200, 10%, 90%, .7);
    box-sizing: border-box;
    overflow: hidden;
    transition:.5s;
}
