.RegionDiv {
    position: absolute;
    width:900px;
    /* width:10000px; */
    height:600px;
    /* background-color: rgba(50, 200, 100, .2); */
    background-color: rgba(122, 122, 122, 0.2);
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    pointer-events: none;
    /* border-radius: 18px; */
    border-radius: 5px;
    overflow: hidden;
}

.RegionTopDiv{
    position: absolute;
    width:100%;
    top:0px;
    height:50px;
    background-color: rgba(255, 255, 255, .33);
    /* background-color: rgba(0, 0, 0, 0.05);
    color: white; */
    transition:.2s;
    pointer-events: auto;
    cursor: pointer;
    /* z-index: 100; */
    font-size: 42px;
    text-align: left;
    padding:  2px 10px 2px 20px;
    font-family: 'Rokkitt', serif;
    /* color:white; */
    opacity: .8;

    height:200px;
    font-size: 200px;
    transition: height 0s;
    transition: font-size 0s;

}
.RegionTopDiv:hover{
    background-color: rgba(255, 255, 255, .5);

}
.RegionTopDiv:active{
    background-color: rgba(255, 255, 255, .7);

}

/* .ApplyHover:hover{
    background-color: rgba(216, 32, 0, 0.459);

} */



.RegionScaleHandle{
    position: absolute;
    bottom: 1px;
    right: -9px;
    /* right: 1px; */
    /* width:20px;
    height:20px; */
    /* background-color:blue; */
    border-left: 20px solid transparent;  /* left arrow slant */
	border-right: 20px solid transparent; /* right arrow slant */
	/* border-bottom: 20px solid black;  */
	border-bottom: 20px solid white; /* bottom, add background color here */
	font-size: 0;
    line-height: 0;
    opacity:0.1;
    /* transform: rotate 45; */
    transform: rotate(135deg);
    cursor: nw-resize;
    pointer-events: auto;
    /* scale:3; */
    /* transform: scale(5); */
    
}
.RegionScaleHandle:hover{
    opacity:.5;
    

}
.RegionScaleHandle:active{
    opacity:1;
    /* border-bottom: 20px solid red;  */
    /* cursor: nw-resize; */


}

.RegionSelected{
    box-shadow:0px 0px 0px 4px rgba(255, 255, 255, 1);
}

.TestImage{
    position: relative;
    object-fit: cover;
    /* object-fit: fill ; */
    /* height:calc(100% - 40px); */
    height:100%;
    /* top:40px; */
    width:100%;
    pointer-events: none;
    /* opacity:.5; */
    /* z-index: 0; */
    /* display:none; */
}

#regionMagnet {
    position: absolute;
    top:2px;
    right:4px;
    width:50px;
    height:50px;
    border-radius: 50%;
    border-radius: 8px;
    font-size: 30px;
    color:rgba(0, 0, 0, .25);
    /* opacity: .3; */
    /* background-color: chartreuse; */
    pointer-events: auto;
    /* opacity: .3; */
    cursor: pointer;
    padding:10px;
    box-sizing: border-box;


}
#regionMagnet:hover {
    /* opacity:1; */
    background-color: rgba(255, 255, 255, .4);

}
#regionMagnet:active {
    /* opacity:1; */
    /* color:white; */
    background-color: rgba(255, 255, 255, .6);


}
/* .regionMagnetOn {
    opacity:1;
    color:white;

} */



#regionMagnetShadow {
    position: absolute;
    top:14px;
    right:40px;
    font-size: 35px;
    color:black;
    opacity:.1;
}
