.StoryItemPanel {
    position: relative;
    width:100%;
    height:100%;
    /* padding: 100px 0px 50px 0px; */
    box-sizing: border-box;
    font-family: 'Open Sans Condensed', sans-serif;
    /* background: linear-gradient(rgb(215, 211, 219), rgb(184, 172, 187)); */
    /* background: linear-gradient(rgb(85, 85, 85), rgb(77, 77, 77)); */
    /* background: hsl(0, 0, .2) */
    overflow-y:auto;
    /* padding: 30px; */
    /* display: flex;
    flex-direction: column;
    gap:10px; */

}


.StoryItemPanel-create-new-group {
    margin:15px 28px;
    position: relative;
    /* box-sizing: border-box; */
    font-family: 'Open Sans Condensed', sans-serif;
    color: white;
    /* opacity: .75; */
    text-align: left;
    padding: 0px 30px 0px;

    /* height:100px; */
    /* margin: 30px; */
    font-size: 28px;
    /* margin: 30px 0px ; */
    /* transition:.2s; */
    height:42px;
    /* width:250px; */
    border-radius: 6px;
    border-style: dashed; 
    /* box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0); */
    /* border-color: rgba(0, 0, 0, 0); */
    border-color: white;

    opacity: .5;

    /* outline-style: dashed;
    outline-color: white; */


}

.StoryItemPanel-create-new-group:hover{
    opacity: .8;
    background-color: rgba(255,255,255,.1);

}
.StoryItemPanel-create-new-group:active{
    opacity: 1;

}


.ItemGroupContainer-spacer-div{
    /* margin:15px 20px; */
    /* width:100%; */
    /* background-color: seagreen; */
}

.testFlexDiv{
    position: relative;
    display: flex;
    /* flex-wrap: wrap; */
    /* flex-flow: row wrap; */
    overflow: hidden;
    gap: 20px;
    /* transition: 1s; */
    padding:14px;
    box-sizing: border-box;
    flex-direction: column;
  }