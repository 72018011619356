.TimeCursorDiv{
    position: absolute;
    height: 100%;
    width:10px;
    left:-5px;
    background-color: orangered;
    opacity:.5;
    /* margin:0px 0px 0px -2px; */

    cursor: ew-resize;
    transition: height .2s, width .2s, margin-left .2s, opacity .2s;
    /* transition: height 1s, width 1s; */
}

