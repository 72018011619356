.MyFlowPanelParent{
    position: absolute;
    width:100%;
    height:100%;
}
.MyFlowPanel{
    position: absolute;
    width:100%;
    height:100%;

    background: linear-gradient(rgb(172, 169, 165), rgb(139, 135, 128));
    background-size: 30px 30px;

    background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.08) 1px, transparent 1px), 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 1px, transparent 1px);


}


.FlowPanel{
    position: absolute;
    width:100%;
    height:100%;

}

.BreadCrumbsDiv{
    display:flex;
    position: absolute;
    /* width:100%; */
    left:0px;
    top:0px;
    height:55px;
    padding:4px 4px 4px 16px;
    background-color: rgba(143, 143, 143, 0.4);
    /* background-color: rgb(238, 225, 169); */

    box-sizing: border-box;
    border-radius: 8px;
    /* transition: .5s; */


}

.BreadCrumb{
    display: inline;
    /* height:40px; */
    position: relative;
    /* background-color: rgb(238, 225, 169); */
    padding:13px 4px ;
    /* margin:5px; */
    border-radius: 4px;
    box-sizing: border-box;
    /* box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3); */
    font-family: 'Rokkitt', serif;
    font-size:20px;
    cursor: zoom-in;
    min-width: 40px;



}


.BreadCrumb:hover{
    background-color: rgb(245, 234, 184);
    box-shadow: 1px 6px 19px 1px rgba(0, 0, 0, .2);

    /* user-select: none;  */
}

.BreadCrumbCurrent{
    display: inline;
    /* height:40px; */
    
    position: relative;
    background-color: rgb(238, 225, 169);
    padding:6px 10px ;
    /* margin:5px; */
    border-radius: 5px;
    box-sizing: border-box;
    /* box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3); */
    font-family: 'Rokkitt', serif;
    font-size:29px;
    cursor: pointer;

    /* color:white; */
    /* margin-left: 12px;
    margin-right: 12px; */
    min-width: 50px;


}

.BreadCrumbCurrent:hover{
    background-color: rgb(245, 234, 184);
    box-shadow: 1px 6px 19px 1px rgba(0, 0, 0, .2);

    /* user-select: none;  */
}

.VP_label{
    color:white;
    font-size: 50px;
    top:-56px;
    font-family: 'Rokkitt', serif;
    position: relative;
    display: inline;
    margin-left: 12px;
    margin-right: 12px;

}


.BreadCrumbSlash{
    display: inline;
    position: relative;
    padding:13px 5px ;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: 'Rokkitt', serif;
    font-size:20px;
}

.BreadCrumbHome{

    display: inline;
    /* height:40px; */
    position: relative;
    /* background-color: rgb(238, 225, 169); */
    padding:13px 4px ;
    /* margin:5px; */
    border-radius: 5px;
    box-sizing: border-box;
    /* box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3); */
    font-family: 'Rokkitt', serif;
    font-size:20px;
    cursor: zoom-in;



}


.BreadCrumbHome:hover{
    /* background-color: rgb(245, 234, 184); */
    background-color: rgba(255, 255, 255, .3);
    box-shadow: 1px 6px 19px 1px rgba(0, 0, 0, .2);
    /* border-style: solid; */

    /* user-select: none;  */
}

.xxxtest{
    position: absolute;
    left:0px;
    top:0px;
    width:2px;
    height:2px;
    background-color:orange;
    z-index: 100;
}
.xxxtest2{
    position: absolute;
    left:0px;
    top:0px;
    width:20px;
    height:20px;
    background-color:orangered;
    z-index: 100;
}