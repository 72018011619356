.MapItemDiv{
    position: absolute;
    /* width:100px; */
    /* height:50px; */
    /* background-color: hotpink; */
    /* border-radius: 50%; */
    box-sizing: border-box;
    /* width:2px; */
    /* height:2px; */
    /* transform: translate(-1px, -1px) */
    /* transition: transform 0.07s; */

}




.MapImageCutter {
    position: absolute;
    border-radius:100%;
    /* transition: .2s; */
    border-style: solid;
    border-width: 3px;
    border-color: rgb(255, 0, 0);
    overflow: hidden;
    /* display:flex; */  
    width:50px;
    height:50px;
    top:-75px;
    left:-27px;
    /* margin:auto; */
    /* left:0px;
    right:0px; */
    /* opacity:.2; */
    background-color: red;
    border-color: rgb(218, 53, 23);

    transition: transform 0.07s;

}
.MapImageCutter:hover{
    border-color: rgb(255, 108, 108);
    background-color: rgb(255, 108, 108);
}




.MapPinLine{
    position: absolute;
    border-radius:4px;
    /* transition: .2s; */
    border-style: solid;
    border-width: 4px;
    border-color: rgb(255, 0, 0);
    border-color: rgb(218, 53, 23);
    width:0px;
    height:18px;
    top:-24px;
    left:-3px;
    /* margin:auto; */
    /* left:0px;
    right:0px; */
    /* opacity:.2; */
    transition: transform 0.07s;

}

.MapPinShadow{
    position: absolute;
    border-radius:12px;
    /* transition: .2s; */
    /* border-style: solid; */
    /* border-width: 4px; */
    /* border-color: rgb(255, 0, 0); */
    width:12px;
    height:20px;
    top:-18px;
    left:-26px;
    /* background-image: linear-gradient( black orange);
    background-color: orange; */
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.02), rgba(0,0,0,.05),   rgba(0,0,0,.1), rgba(0,0,0,.5));

    transform: skewX(65deg);

}
.MapPinShadow-dragging{
    position: absolute;
    border-radius:12px;
    /* transition: .2s; */
    /* border-style: solid; */
    /* border-width: 4px; */
    /* border-color: rgb(255, 0, 0); */
    /* width:12px;
    height:20px;
    top:-24px;
    left:-30px; */

    width:28px;
    height:20px;
    top:-20px;
    left:-34px;


    /* background-image: linear-gradient( black orange);
    background-color: orange; */
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.02), rgba(0,0,0,.05),   rgba(0,0,0,.07), rgba(0,0,0,.1));

    transform: skewX(60deg);

}

.MapPinAmbOcc{
    position: absolute;
    border-color: green;
    border-radius: 5px;
    width:2px;
    height:1px;
    top:0px;
    left:-1px;
    background-color:  rgba(0, 0, 0, .8);
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 1);
    transform: scaleY(.5);

}
.MapPinAmbOcc-dragging{
    position: absolute;
    border-color: green;
    border-radius: 5px;
    width:8px;
    height:16px;
    top:-8px;
    left:-4px;
    background-color:  rgba(0, 0, 0, .3);
    box-shadow: 0px 0px 18px 8px rgba(0, 0, 0, .2);
    transform: scaleY(.2);

}


.mapItemDragging{
    /* transform: translateY(-15px) scale(.8); */
    transform: translateY(-12px);
    transition: transform 0.07s;
}

.MapItemLabel {
    position: absolute;
    /* top:-40px; */
    /* bottom:70px; */
    top:5px;
    top:-28px;
    /* top:10px; */
    left: -40px;
    font-family: 'Rokkitt', serif;
    margin:auto;
     background-color: rgba(255, 255, 255, 0.66); 
     border-radius: 5px;
     padding: 0px 1px;
     font-size: 12px;
     max-width: 200px;
     word-wrap: break-word;
     min-width: 80px;
         /* margin-top:2px; */

    /* border-style: solid; */
    transition: transform 0.07s;


    }

    #MapIcon_noImage_small{
        position: relative;
        font-size:28px;
        top:6px;
        color: white;
        opacity: .8;
        /* background-color: white; */
        /* margin: auto; */
    }