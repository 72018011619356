.item-group-container-parent{
    /* top:30px;
    margin: 20px auto ;
    position:relative;
    width:60%;
    box-sizing: border-box;
    border-style: solid;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(213, 230, 69, 0.3); */


    border-radius: 3px;
    /* border-style: solid;  */
    border-style: none; 
    /* box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05); */
    box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, .1);

    transition: flex-basis 500ms ease-in-out;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.05);

    min-height: 100px;
    transition: .1s;
    /* color:black; */
  }

  .item-group-container-parent-collapsed{
    min-height: 0px;
    transition: .1s;

  }

.item-group-container-parent-selected{
    outline-style: solid;
    outline-width: 2px;
    outline-color: white;

}
.item-group-container-header {
    /* position: absolute; */
    /* display: fixed; */
    text-align: left;
    /* padding: 30px 24px 0px; */

    width:100%;
    /* height:30px; */
    /* margin: 30px; */
    font-size: 26px;
    /* background-color: rgba(255, 0, 0, 0.3); */
    background-color: rgba(22, 36, 61, 0.1);
    /* color: white; */
    /* transition:.2s; */
    font-family: 'Open Sans Condensed', sans-serif;
    box-sizing: border-box;
    display: flex;


  }

  .item-group-container-header:hover {
    transition:.1s;
    background-color: rgba(34, 52, 90, 0.3);
    /* outline-style:solid;
    outline-color: rgba(255, 255, 255, .4);
    outline-width: 3px; */
    /* border-style: solid;  */

    /* background-color: rgba(37, 61, 141, 0.3); */
  }
.item-group-container-header:active {
    transition:.1s;
    background-color: rgba(34, 52, 90, 0.5);
    /* border-style: solid;  */

    /* background-color: rgba(37, 61, 141, 0.3); */
}

.item-group-container-label {
    position: relative;

    left:20px;
}

.item-group-container-arrow {
    opacity:0.5;
    position: relative;
    left:12px;
    top:14px;
    width: 0; 
    height: 0; 
}
.item-group-container-arrow:hover {
    opacity:0.8;
}
.item-group-container-arrow:active {
    opacity:1;
}

.item-group-container-arrow-right {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid white;
    margin-right: 4px;
}

.item-group-container-arrow-down {
    top:17px;
    left:8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid white;
}






.GroupNameField {
    /* resize: none;  */
    /* position: fixed; */
    /* text-align: right; */
    /* left:40px;
    top:5px; */
    /* min-width: 300px; */
    max-width: 300px;
    top:0px;
    bottom:0px;
    /* right:0px; */
    width:90%;
    /* height: 100%; */
    /* width: 200px; */
    font-size: 24px;
    /* padding: 5px 0px 5px 0px; */
    /* font-family: 'Rokkitt', serif; */
    font-family: 'Open Sans Condensed', sans-serif;

    /* padding: 5px 5px; */
    

    margin: 2px 8px;
    color: white;
    color: rgba(0, 0, 0, .5);
    background-color: rgba(255, 255, 255, 0);
    /* border-color: rgba(255, 255, 255, 0); */
    border-style: none;
    border-radius: 3px;
    /* box-shadow: inset 0px 0px 0px rgba(0,0,0,0.0); */

    /* box-sizing: border-box; */
    transition: .3s;
    outline: none;
    /* line-height: 10px; */

}
.GroupNameField:hover {
    /* transition-property: background-color, box-shadow; */
    background-color: rgba(255, 255, 255, .2);
    /* color: black; */
    /* transition: .1s; */
    /* box-shadow: inset 1px 1px 3px 1px rgba(0,0,0,0.2); */
    /* color: rgba(255, 255, 255, 1); */

}
.GroupNameField:focus { 
    background-color: rgba(255, 255, 255, .4);
    /* box-shadow: inset 2px 4px 12px 0px rgba(0, 0, 0, .2); */
    /* box-shadow: inset 0px 0px 4px rgba(0,0,0,1); */
    color: black;
    /* border-style: none; */
    transition: 0s;
}

.GroupNameField-disable{
    pointer-events: none;
}

.item-group-container-collapsed-target{
    position:absolute;
    left:120px;
    right:20px;
    /* top:-30px; */
    /* right:50px; */
    /* bottom:0px; */
    height: 45px;
    /* width:300px; */
    /* background-color: orange; */
    /* z-index: 1000; */
    pointer-events: none;
}