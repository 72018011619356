

.HomeBaseDiv{
    position: fixed;
    width:100%;
    height:100%;
    left:0px;
    right:0px;
    top:0px;
    bottom:0px;
    /* background-color: blue; */
    /* background: linear-gradient(to top , hsl(200, 8%, 55%) ,hsl(200, 10%, 50%)); */

    /* from .App class */
    text-align: center;
    /*user-select: none;  Non-prefixed version, currently*/
    /* overflow-x: hidden;  */
}

.HomeLeftDiv{
    position: absolute;
    left:0px;
    width:60%;
    top:0px;
    bottom:0px;
    /* background-color: blue; */
    background: linear-gradient(hsl(200, 4%, 70%) ,hsl(200, 5%, 66%));
    background: linear-gradient(to top , hsl(200, 8%, 55%) ,hsl(200, 10%, 50%));
    text-align: center;

}

.HomeRightDiv{
    position: absolute;
    left:60%;
    right:0px;
    top:100px;
    bottom:0px;
    /* background-color: blue; */
}

.homePageTitle{
    position: relative;
    /* left:10px; */
    top:0px;
    width:100%;
    left:-20px;
    font-family: 'Suranna', serif;
    font-size: 80px;
    color: white;
    text-align: center;
}
.homePageTitlebeta{
    position: absolute;
    /* left:10px; */
    top:92px;
    left:140px;
    width:100%;
    font-family: 'Suranna', serif;
    font-size: 20px;
    color: white;
    text-align: center;
}
.homePageHeading{
    position: relative;
    /* left:10px; */
    top:0px;
    width:100%;
    font-family: 'Suranna', serif;
    font-size: 50px;
    color: white;
    text-align: center;
}
.homePageBlurb{
    position: relative;
    /* left:10px; */
    /* top:200px; */
    width:100%;
    /* font-family: 'Suranna', serif; */
    /* font-family: 'Open Sans Condensed', sans-serif; */
    font-family:'Urbanist', sans-serif;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 30px;
    color: white;
    text-align: center;
    white-space: pre-line;
}
.homePageBlurb_b{
    position: relative;
    /* left:10px; */
    /* top:200px; */
    width:100%;
    /* font-family: 'Suranna', serif; */
    /* font-family: 'Open Sans Condensed', sans-serif; */
    font-family:'Urbanist', sans-serif;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: white;
    text-align: center;
    white-space: pre-line;
}

.preview_image_right{
    position: relative;
    top:0px;
    left:20%;
    /* right:80px; */
    /* left:calc(55% + 30px); */
    /* right:10px; */
    /* max-width:700px; */
    /* width:800px;     */
    width:30%;
    height: 30%;
    /* max-width:700px; */

    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3);

    border-style: none;
    border-radius: 10px;

}
.loginButton{


    position: absolute;
    right:110px;
    width:90px;
    height:35px;
    background-color: white;
    border-radius: 4px;
    color: hsl(200, 50%, 35%);
    font-size: 18px;
    text-decoration: none;
    box-sizing: border-box;
    text-align: center;
    padding:5px;
    margin: 5px;    /* text-decoration: none; */
    font-weight: bold;


}
.loginButton:hover{
    text-decoration: underline;
    color: hsl(200, 50%, 35%);

}

.signupButton{
    position: absolute;
    right:10px;
    width:90px;
    height:35px;
    background-color: hsl(200, 50%, 35%);
    border-radius: 4px;
    color: white;
    font-size: 18px;
    text-decoration: none;
    box-sizing: border-box;
    text-align: center;
    padding:5px;
    margin: 5px;
    font-weight: bold;


}
.signupButton_hover:hover{
    /* text-decoration: underline; */
    background-color: hsl(200, 50%, 30%);

}

.HomeTopDiv{
    position: fixed;
    left:0px;
    width:100%;
    top:0px;
    height:50px;
    z-index: 1;
    /* background: linear-gradient(   hsla(200, 50%, 35%, 1),hsl(200, 50%, 30%, 1) ); */
    /* background: linear-gradient(   rgba(255, 255, 255, .7),  rgba(255, 255, 255, 0)); */
    /* font-family: 'Suranna', serif; */
    font-family: 'Open Sans Condensed', sans-serif;
    /* font-family: 'Poppins', sans-serif; */
    /* font-family: 'Roboto Slab', serif; */
    background-color: rgba(0, 0, 0, .5);
    /* backdrop-filter: blur(2px); */

}

.HomePageImg{
    position: relative;    
    width:100%;
    height:100%;
    /* left:0px;
    right:0px;
    top:0px; */
    bottom:0px;
    object-fit: cover;
    /* object-fit:contain; */
    
}



.HomePageBlurBG {
    position: relative;

    margin:70px 0px;
    /* height:600px; */
    margin: 70px auto;

    /* left:calc(50% - 330px); */
    min-width:660px;
    max-width:960px;

    width: 70%;
    background: linear-gradient(to top right, hsla(200, 4%, 70%, .5) ,hsl(200, 10%, 50%, .5));

    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3);

    border-style: none;
    border-radius: 10px;

    backdrop-filter: blur(10px);
    padding:0px 20px 60px 20px;
    box-sizing: border-box;
}


.HomePageBlurBG_left {
    position: relative;

    margin:70px 0px;
    /* height:600px; */
    /* min-width:560px;
    max-width:360px; */
    /* left:calc(50% - 700px); */
    left:10%;
    /* right:calc(50% - 10px); */
    /* max-width: 600px; */
    width:40%;

    /* width:660px; */

    background: linear-gradient(to top right, hsla(200, 4%, 70%, .5) ,hsl(200, 10%, 50%, .5));

    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3);

    border-style: none;
    border-radius: 10px;

    backdrop-filter: blur(10px);
    padding:0px 20px 60px 20px;
    box-sizing: border-box;
}

.HomePageBlock{
    position: relative;
    width:100%;
    display: flex;
    margin: 30px 0px;
}
.HomePageLogedInAs{
    position: absolute;
    color: white;
    font-size: 24px;
    height:35px;
    text-align: center;
    padding:2px 20px;
    margin: 5px;
    border-radius: 4px;
    box-sizing: border-box;

    right:10px;
    top:40px;
    /* min-width: 290px; */
    /* background-color: rgba(0, 0, 0, .5); */


    right:320px;
    top:0px;

}

.HomePageGoToProjects{
    width:146px;
    right:55px;
}
.HomePageLogOut{
    /* width:190px; */
    right:210px;
    cursor: pointer;
}


.HomePageScrollDiv{
    position: absolute;
    width: 100%;
    /* height: 100%; */
    top:0px;
    bottom:0px;
    /* background-color: chartreuse; */
    scroll-behavior: auto;
    /* position: absolute; */
    z-index: 1; 
    /* bottom:0px;
    width:100%; */
    overflow-x: hidden; /* Disable horizontal scroll */
    /* padding: 0px 12px 24px 12px; */
    box-sizing: border-box;



}



/* width */
::-webkit-scrollbar {
    width: 10px;
    /* border-radius: 4px; */
    opacity: .2;
  
  } 
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0,0,0,0); 
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .12); 
  } 
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    width:5px;
    border-radius: 4px;
    background: hsla(200, 0%, 100%, .2) ;
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,.1); 
    /* border-style: solid;
    border-width: 2px;
    border-color: rgba(255, 255, 255, .5); */
  
  }
  ::-webkit-scrollbar-thumb:hover {
    background: hsla(200, 0%, 100%, .6) ;
  
  }
  
  ::-webkit-scrollbar-corner
  {
    background: rgba(0,0,0,0); 
    /* border-radius: 4px; */
    border-style:none; 
    outline: none;
  }

  .beta_message{
    color: white;
    position: relative;
    /* top:60px; */

    /* margin:70px 0px 0px 0px; */
    /* height:600px; */
    margin: 70px auto -50px auto;

    /* left:calc(50% - 330px); */
    /* min-width:860px;
    max-width:960px; */

    width: 80%;
    /* background: linear-gradient(to top right, hsla(200, 4%, 70%, .5) ,hsl(200, 10%, 50%, .5)); */
    /* background: linear-gradient(to top right, #bb999980 ,rgba(152, 97, 97, 0.5)); */
    background: linear-gradient(to top, rgba(255, 0, 0, 0.6) ,rgba(255, 91, 37, 0.6));
    /* background-color: red; */

    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3);

    border-style: none;
    border-radius: 10px;

    backdrop-filter: blur(10px);
    padding:20px;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 26px;
    color: white;
    text-align: center;
    white-space: pre-line;
}

