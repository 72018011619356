

.LoginPageDiv {

    position: fixed;
    width:100%;
    height:100%;
    left:0px;
    right:0px;
    top:0px;
    bottom:0px;
    /* background-color: blue; */
    /* background: linear-gradient(to top , hsl(200, 8%, 55%) ,hsl(200, 10%, 50%)); */

    /* from .App class */
    text-align: center;
    /*user-select: none;  Non-prefixed version, currently*/
    /* overflow-x: hidden;  */
}
.LoginPageImg{
    position: relative;    
    width:100%;
    height:100%;
    /* left:0px;
    right:0px;
    top:0px; */
    bottom:0px;
    object-fit: cover;
    /* object-fit:contain; */
    
}

.LoginPageForm {
    position: absolute;
    /* margin: auto; */
    /* top:calc(50% - 300px); */
    top:160px;
    height:calc(100% - 260px);
    /* height:70%; */
    /* bottom:80px; */
    left:calc(50% - 175px);
    width:390px;
    /* height:600px; */
    max-height: 600px;
    min-height: 380px;
    padding:30px;
    /* background-color: rgba(255, 255, 255, .15); */
    background: linear-gradient(to top right, hsla(200, 4%, 70%, .5) ,hsl(200, 10%, 50%, .5));
    /* background-color: rgba(255, 255, 255, 1); */
    backdrop-filter: blur(10px);
    /* background: linear-gradient(to top right, hsl(200, 4%, 70%) ,hsl(200, 10%, 50%)); */

    /* background: linear-gradient(rgb(233, 229, 204), rgb(238, 236, 204)); */

    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3);
    /* font-family:Courier, monospace; */
    /* font-family:Arial, Helvetica, sans-serif ; */
    border-style: none;
    border-radius: 4px;
    font-size: 18px;
    transition: .15s;
    transform-origin: 50% 50%;
    /* transform: translateX(500px); */

    font-family: 'Open Sans Condensed', sans-serif;
    z-index: 12; 
    /* padding: 20px; */
    box-sizing: border-box;

}


.LoginTitle {
    position:absolute;
    top:50px;
    width:100%;
    margin:auto;
    /* width:300px; */
    font-size: 75px;
    line-height: 1;
    color:white;
    font-family: 'Suranna', serif;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 1);

    /* height:140px; */
}
.LoginTitleLink:hover{
    color:rgb(224, 224, 224);
    /* text-shadow: 0px 4px 10px rgba(255, 255, 255, .2); */
    /* top:20px; */

}

.LoginHeading {
    margin:auto;
    /* width:300px; */
    /* font-size: 30px; */
    font-size: 25px;
    /* line-height: 1; */
    color:white;
    /* color:greenyellow; */
    /* font-family: 'Suranna', serif; */
    /* height:140px; */
}

.LoginTitleLink{
    color:white;
    /* color:rgb(108, 189, 126); */
    text-decoration: none;
}


.LoginText {
    color:white;
    position:absolute;
    left:60px;
    bottom:10px;
    /* color:rgb(36, 218, 75);
    font-weight: bold; */

}

.LoginMesssageSuccess{
    background-color: rgba(70, 199, 70, 0.4);
    border-radius: 4px;
    height:35px;
    padding-top: 10px;
    color:white;
}
.LoginMesssageError{
    background-color: rgba(199, 70, 70, 0.4);
    border-radius: 4px;
    /* height:35px; */
    padding-top: 10px;
    color:white;
}



.LoginLink {
    /* display: block; */
    color:white;
    position:absolute;
    /* height:20px; */
    left:210px;
    bottom:20px;
    font-weight: bold;
    cursor: pointer;
    padding:10px 14px;
    border-radius: 4px;
    transition: .1s;

}
.LoginLink:hover{
    /* color:red; */
    /* background-color: rgba(199, 70, 70, 0.4); */
    background-color: rgba(70, 199, 70, 0.4);
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);

}


.BackToLoginLink {
    /* display: block; */
    color:white;
    position:relative;
    /* height:20px; */
    /* left:220px;
    bottom:29px; */
    /* padding-top: 50px;
    height:100px; */
    top:30px;
    font-weight: bold;
    cursor: pointer;
    opacity: .7;

}
.BackToLoginLink:hover{
    /* color:grey; */
    opacity: 1;

}

.ForgotPasswordLink {
    /* display: block; */
    color:white;
    position:relative;
    /* height:20px; */
    /* left:220px;
    bottom:29px; */
    /* padding-top: 50px;
    height:100px; */
    top:40px;
    /* font-weight: bold; */
    cursor: pointer;
    opacity: .7;

}
.ForgotPasswordLink:hover{
    /* color:grey; */
    opacity: 1;

}
/* 
.EmailField{
    color:white;
    font-size:14px;
    width:100%;
    border-style:none;
    margin:10px 0px;
}
.PwField{
    color:white;
    font-size:14px;
    width:100%;
    border-style:none;
    margin:10px 0px;
} */