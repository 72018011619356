.linkItemWindow{
    position: absolute;
    width:100%;
    height:100%;
    /* background-color:red; */
    /* margin:20px; */
    /* padding:5px; */
    /* margin: auto; */
    /* box-sizing: border-box; */

}
.TopSectionDiv{
    /* width:100%; */
    left:10px;
    right:10px;

    top:0px;
    height:110px;
    /* bottom:77%; */
    position: absolute;
    /* background-color:rgba(255,255,255,.15); */
    border-color: rgba(255,255,255,.35);
    border-style: solid;
    border-radius: 6px;
    display: flex;
    /* font-size:30px; */

}
.BottomSectionDiv{
    left:10px;
    right:10px;
    top:25%;
    bottom:0px;
    position: absolute;
    /* background-color:green ; */
    /* background-color:rgba(255,255,255,.15); */
    /* background-color:rgba(0,0,0,.15); */
    border-color: rgba(255,255,255,.35);
    border-style: solid;
    border-radius: 6px;
    padding:10px;



}

.AddLabel{
    /* margin:auto; */
    padding:35px 10px;
    font-size:30px;
    left:-20px;
}