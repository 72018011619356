.uber-flex-parent {
    position: relative;
    margin: auto;
    overflow: hidden;
    transition: 1s;

  }
.uber-flex {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    overflow: hidden;
    gap: 20px;
    transition: 1s;
    padding:14px;
    box-sizing: border-box;
    /* flex-direction: column; */
  }


  
.flex-drop-target-parent{
    position: fixed;
    /* padding: 20px; */

    /* box-sizing: border-box; */
    /* background-color: rgba(240, 39, 39, 0.5); */
    /* filter: blur(10); */
    /* border-radius: 10px; */
    z-index: 10;

}
.flex-drop-target-copy{
    position: absolute;
    left: calc(50% + 2px);
    right: 5px;
    top:5px;
    bottom:5px;
    border-radius: 10px;
    border-style: dashed;
    box-sizing: border-box;
    border-color: black;
    background-color: rgba(136, 136, 136, 0.5);

    border-color: rgba(255, 255, 255, 0.5);
    /* border-color: rgba(0, 0, 0, 0.5); */

    border-width: 3px;
    color: rgba(255, 255, 255, 0.5);
    /* color: rgba(0, 0, 0, 0.5); */
    font-size: 30px;

    vertical-align: middle;
    display: flex;
    align-items:center;
    justify-content:center;

    backdrop-filter: blur(3px);

  
}

.flex-drop-target-copy:hover{
    background-color: rgba(207, 207, 207, 0.63);
    border-color: white;
    color: white;
    border-style: solid;


}
.flex-drop-target-move{
    position: absolute;
    left: 5px;
    right: calc(50% + 2px);
    top:5px;
    bottom:5px;
    border-radius: 10px;
    border-style: dashed;
    box-sizing: border-box;
    border-color: black;
    background-color: rgba(136, 136, 136, 0.5);

    border-color: rgba(255, 255, 255, 0.5);
    /* border-color: rgba(0, 0, 0, 0.5); */
    border-width: 3px;
    color: rgba(255, 255, 255, 0.5);
    /* color: rgba(0, 0, 0, 0.5); */
    font-size: 30px;
    vertical-align: middle;
    display: flex;
    align-items:center;
    justify-content:center;

    backdrop-filter: blur(3px);

}
.flex-drop-target-move:hover{
    background-color: rgba(207, 207, 207, 0.63);
    border-color: white;
    color: white;
    border-style: solid;

}


.uber-flex-item{
    position: relative;
    /* background-color: mediumvioletred; */
    /* height: 50px;
    width: 50px; */
    /* transition: .3s; */
    user-select: none; /* Standard */
    border-radius: 6px;
    /* outline: 2px rgba(76, 0, 130, 0.294); */
    outline-color: rgba(255, 255, 255, 0.0);
    outline-width: 2px;
    outline-style: solid;
    /* outline-offset: -3px; */
    /* margin:2px; */
    /* box-sizing: border-box; */
    /* line-height: 50px; */
    /* margin: auto; */
    /* text-align: center; */
    /* padding: 4px; */
}
.uber-flex-item-hover:hover{
    outline-color: rgba(255, 255, 255, 0.5);

}

.uber-flex-item-add-new{
    outline-width: 2px;
    outline-style: dashed;
    outline-color: white;
    outline-offset: -20px;
    opacity:.5;
    user-select: none; /* Standard */
    border-radius: 30px;
    position: relative;
    line-height: 100%;
    font-size: 50px;
    font-family: 'Rokkitt', serif;
    color: white;
    /* padding:20px; */
    box-sizing: border-box;

}

.uber-flex-item-add-new:hover{
    outline-color: white;
    opacity:0.8;
}
.uber-flex-item-add-new:active{
    outline-color: white;
    opacity:1;
}
