.UserSettingsDiv {

    overflow: hidden;
    max-height: 300px;
    width:100%;
    height:100%;

}

.UserSettingsTopCentreArea{
    position: relative;
    /* background-color: royalblue; */
    top:0px;
    left:20px;
    left:calc(50% - 80px);
    /* width:calc(100% - (160px + 70px)); */
    width:160px;
    height:160px;
    /* padding:10px; */
    box-sizing: border-box;
    /* background-color: orange; */
}

.UserSettingsCentreArea{
    position: absolute;
    bottom:10px;
    right:150px;
    left:150px;
    height:calc(100% - (160px + 10px));
    box-sizing: border-box;
    /* background-color: green; */
}

.UserSettingsBottomCentreArea{
    position: absolute;
    bottom:10px;
    right:10px;
    left:10px;
    height:80px;
    box-sizing: border-box;
    /* background-color: green; */
}

