
.MainPanelBackgroundDiv{
    position: fixed;
    top: 36px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: linear-gradient(rgb(219, 216, 211), rgb(187, 181, 172));

}

#MainPanel {
    position: fixed;
    z-index: 0; /* Stay on top */
    top: 45px;
    bottom: 0px;
    left: 170px;
    right: 500px;
    transition: 0.2s;

}