
.LeftSectionDiv{
    position: absolute;
    left:10px;
    width:30%;
    top:0px;
    bottom:60px;
    /* height:100%; */
    position: absolute;
    /* border-color: rgba(255,255,255,.35); */
    /* border-style: solid; */
    border-radius: 6px;
    display: flex;
    padding:4px;
    /* background-color: lawngreen; */
    box-sizing: border-box;
}

.RightSectionDiv{
    left:35%;
    right:10px;
    top:0px;
    bottom:60px;

    position: absolute;
    border-color: rgba(255,255,255,.35);
    border-style: solid;
    border-radius: 6px;
    padding:10px;

    scroll-behavior: auto;
    overflow-x: hidden;

}
