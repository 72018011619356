/* :root {
    --banner_height: 90px;
}
   */

.homeButtonHoverLabel {

    position: fixed;
    top:20px;
    left:20px;
    color: red;
    pointer-events: none;
    opacity: 0.7;
    font-size: 26px;
    color: white;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: bold;
    /* text-shadow: 4px 4px 5px black; */
    background-color: hsl(200, 50%, 30%);
    border-radius: 6px;
    padding: 0px 10px 2px 10px;
    transition: .2s;
    /* margin: 6px; */
}

#BannerPanel {
    height: 40px;
    width: 100%; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 10; 
    top: 0;
    left: 0;
    /* background-color:hsl(200, 60%, 20%); */
    /* background: linear-gradient( hsl(200, 60%, 20%),  hsl(200, 60%, 10%),  hsl(200, 60%, 17%), hsl(200, 60%, 20%)); */
    background: linear-gradient(   hsl(200, 50%, 35%),hsl(200, 50%, 30%) );
    /* background: linear-gradient(   hsl(352, 50%, 35%),hsl(41, 50%, 30%) ); */

    /* font-family: 'Dancing Script', cursive; */
    /* font-family: 'Lobster', Regular; */
    /* font-family: 'Rufina', serif; */
    /* font-family: 'Josefin Slab', serif; */
    font-family: 'Suranna', serif;
    font-size: 30px;
    color: white;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, .5);
    overflow: hidden;
    
}

#BannerLabel {
    position: relative;
    margin: auto;
    top:-14px;
    width:200px;
    /* pointer-events: none; */
    opacity: 0.75;
    color: white;
    text-decoration: none;

}
#BannerLabel:hover {
    opacity: 1;
}


#VersionLabel {
    position: fixed;
    right:130px;
    top:10px;
    height: 24px;
    width: 24px;
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 16px;
    font-family: 'Open Sans Condensed', sans-serif;

}

/* #UserIcon {
    position: fixed;
    right:10px;
    top:4px;
    height: 32px;
    width: 32px;

    border-radius:100%;
    transition: .2s;
    border-style: none;

}
#UserIcon:hover {
    transition: .2s;
    box-shadow: 0px 0px 8px 3px rgba(255,255,255,.8);

} */

#projectSettingsIcon {
    position: fixed;
    /* text-align: right; */
    right:168px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 28px;
    cursor:pointer;


}

#projectSettingsIcon:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}

#TestingPanelButtonIcon {
    position: fixed;
    /* text-align: right; */            
    right:218px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 28px;
    cursor:pointer;
}
#TestingPanelButtonIcon:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}
#TestingButtonIcon {
    position: fixed;
    /* text-align: right; */            
    right:340px;
    top:6px;
    height: 24px;
    width: 24px;

    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 28px;
    cursor:pointer;
}
#TestingButtonIcon:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}


#UndoButton {
    position: fixed;
    /* text-align: right; */            
    right:298px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    /* transition: .2s; */
    color: rgba(255, 255, 255, .4);
    font-size: 28px;
    cursor:pointer;
}
#UndoButton:hover {
    /* transition-property: background-color, box-shadow; */
    /* transition: .2s; */
    color: rgba(255, 255, 255, .7)
}
#UndoButton:active {
    /* transition-property: background-color, box-shadow; */
    /* transition: .2s; */
    color: rgba(255, 255, 255, 1)
}
#RedoButton {
    position: fixed;
    /* text-align: right; */            
    right:258px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    /* transition: .2s; */
    color: rgba(255, 255, 255, .4);
    font-size: 28px;
    cursor:pointer;
}
#RedoButton:hover {
    /* transition-property: background-color, box-shadow; */
    /* transition: .2s; */
    color: rgba(255, 255, 255, .7)
}
#RedoButton:active {
    /* transition-property: background-color, box-shadow; */
    /* transition: .2s; */
    color: rgba(255, 255, 255, 1)
}
.undo_button_disable{
    color:darkred;
}

#TestButton {
    position: fixed;
    /* text-align: right; */
    right:168px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 28px;

}
#TestButton:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}

#TestButtonB {
    position: fixed;
    /* text-align: right; */
    right:218px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 28px;

}
#TestButtonB:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}

#TestButtonC {
    position: fixed;
    /* text-align: right; */
    right:268px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 28px;

}
#TestButtonC:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}
#TestButtonD {
    position: fixed;
    /* text-align: right; */
    right:268px;
    top:6px;
    height: 24px;
    width: 24px;
    /* opacity: .6; */
    /* max-width: 300px; 
    max-height: 300px; */
    /* border-radius:100%; */
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 28px;

}
#TestButtonD:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}


#FullScreenIcon {
    position: fixed;
    right:66px;
    top:8px;
    height: 24px;
    width: 24px;
    transition: .2s;
    color: rgba(255, 255, 255, .4);
    font-size: 24px;
    cursor:pointer;

}
#FullScreenIcon:hover {
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}


#HomeButton {
    position: fixed;
    /* text-align: right; */
    left:10px;
    top:8px;
    /* width: 200px; */
    font-size: 24px;
    color: rgba(255, 255, 255, .4);
    cursor:pointer;

}
#HomeButton:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}

#ProjectSelector {
    position: fixed;
    /* text-align: right; */
    left:70px;
    top:11px;
    /* width: 200px; */
    font-size: 28px;
    color: rgba(255, 255, 255, .4)
}
#ProjectSelector:hover {
    /* transition-property: background-color, box-shadow; */
    transition: .2s;
    color: rgba(255, 255, 255, 1)
}


.ProjectField {
    resize: none; 
    position: fixed;
    /* text-align: right; */
    left:40px;
    top:5px;
    min-width: 300px;
    max-width: 300px;
    height: 20px;
    /* width: 200px; */
    font-size: 24px;
    /* padding: 5px 0px 5px 0px; */
    font-family: 'Rokkitt', serif;
    padding: 5px 5px;

    margin:0px 10px;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0);
    /* border-color: rgba(255, 255, 255, 0); */
    border-style: none;
    border-radius: 3px;
    box-shadow: inset 0px 0px 0px rgba(0,0,0,0.0);
    /* background-color: rgba(255, 255, 255, .4);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 10px;
    box-shadow: inset 1px 2px 5px 2px rgba(0, 0, 0, .5); */
    /* box-shadow: inset 0px 0px 10px rgba(0,0,0,0.9); */

    transition: .3s;
    outline: none;
    /* transition-property: background-color, box-shadow; */

}
.ProjectField:hover {
    /* transition-property: background-color, box-shadow; */
    background-color: rgba(255, 255, 255, .2);
    /* color: black; */
    transition: .1s;
    box-shadow: inset 1px 1px 3px 1px rgba(0,0,0,0.2);
    /* color: rgba(255, 255, 255, 1); */

}
.ProjectField:focus { 
    background-color: rgba(255, 255, 255, .8);
    /* box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05); */
    box-shadow: inset 0px 0px 0px rgba(0,0,0,1);
    color: black;
    /* border-style: none; */
    transition: 0s;



}


