.AttrEdStoryItemOptions{
    position: relative;
    /* background-color: palegoldenrod; */
    /* opacity: .5; */
    width:100%;
    height:100%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 18px;
    font-weight: bold;
    /* top:-8px; */

}

.AttrEdSubMenuButtons{
    /* font-family: 'Open Sans Condensed', sans-serif; */
    height:14px;
    opacity:0.6;
    padding:10px 0px;
    border-radius: 4px;
    margin: 4px;
}

.AttrEdSubMenuButtons:hover{
    /* opacity:1; */
    background-color: rgba(89, 97, 116, 0.2);
}



/* .AttrEdSubMenuButtons
{
    font-family: 'Open Sans Condensed', sans-serif;
    width: 100%;
    font-size: 20px;
    color:white;
    background-color: rgba(255, 255, 255, .2);
    border-style:none;
    border-radius: 3px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    transition: .2s;
    transition-property: background-color, box-shadow;
    cursor:'pointer';

} */