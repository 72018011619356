
.cardDiv{
    position: relative;
    left:0px;
    top: 0px;
    height:75px;
    width: 130px;   
    padding:0px;
    margin:2px;
}
.card_break{
    position: relative;
    left:0px;
    top: 0px;
    height:0px;
    width: 142px;   
    margin:6px 2px 12px 2px;
    border-width: 3px 0px 0px 0px;
    border-style: dashed;
    border-color: crimson;
    /* padding:50px 0px; */


    /* background-color: crimson; */
}
.card_break:hover{
  border-width: 5px 0px 0px 0px;
  border-color: white;
  margin:5px 2px 11px 2px;

}

.CardsColumn{
    /* display:inline-block; */
    position: absolute;
    left:5px;
    top: 100px;
    bottom:8px;
    width: 165px;
    /* width: 210px; */
    /* width:; */
    /* background-color: rgba(170, 97, 97, 0.5);    */
    scroll-behavior: auto;
    overflow-x: hidden; 
    border-radius: 4px;
    margin:0px 0px;
    padding:8px 10px;
    /* box-sizing: border-box; */
    /* display: inline-block; */
    transition: width, .1s;

    
}
.filterOptions{
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.1); */
  left:20px;
  top: 40px;
  /* bottom:8px; */
  width: 150px;
  height:40px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  /* background-color: rgba(0, 0, 0, .05);    */
  /* scroll-behavior: auto; */
  /* overflow-x: hidden;  */
  /* border-radius: 4px; */
  /* margin:0px 0px; */
  /* padding:0px 14px; */
  box-sizing: border-box;
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 22px;
  color: white;
  /* display: inline-block; */
  text-shadow: 1px 2px 5px rgba(0, 0, 0, .3);
  /* font-weight: bold; */

 
}
.filterOptions:hover{
  background-color: rgba(255, 255, 255, 0.3);
}





#MyWritingPanel{
    /* position: fixed;
    top:100px;
    width:100%;
    padding: 20px; */
    /* position: fixed; */
    /* top:100px;
    width:100%; */

    /* position: fixed; */

    /* padding: 20px; */
    /* display: none; */
    /* top: 100%;
    bottom: 0%; */
    width:100%;
    height:100%;
    padding: 48px 0px 16px 142px;
    box-sizing: border-box;

    /* top:10px;
    bottom: 50px; */
    /* background-color: blueviolet; */
    background: linear-gradient(rgb(219, 216, 211), rgb(187, 181, 172));

}

#chapter_title {
    margin: 0 auto; 
    color: black;
    font-size: 40px;
    font-family: 'Suranna', serif;
    /* box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, .8); */
}



/* width */
.CardsColumn::-webkit-scrollbar {
    width: 10px;
    /* border-radius: 4px; */
  
  } 
  
  /* Track */
  #TextEd::-webkit-scrollbar-track {
    background: rgba(0,0,0,.12); 
    border-radius: 4px;
  }
  
  #TextEd::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 15px rgba(0, 0, 0, .12); 
  } 
  
  /* Handle */
  #TextEd::-webkit-scrollbar-thumb {
    width:5px;
    border-radius: 4px;
    background: hsla(200, 0%, 100%, .4) ;
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,.1); 
    /* border-style: solid;
    border-width: 2px;
    border-color: rgba(255, 255, 255, .5); */
  
  }
  #TextEd::-webkit-scrollbar-thumb:hover {
    background: hsla(200, 0%, 100%, .6) ;
  
  }


  .EmptyDocument {
    /* -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box; */

    /* padding-top: 20%; */
    position: absolute;
    box-sizing: border-box;
    /* left: 240px; */
    left: 30px;
    right: 30px;
    top: 100px;
    bottom: 100px;
    resize: none;
    min-width: 100px;
    max-width: 900px;
    /* max-height: 900px; */

    margin: 0 auto; 


    /* margin: 70px auto;  */
    /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .2); */
    /* font-family:Courier, monospace; */
    font-family:Arial, Helvetica, sans-serif ;
    font-size: 30px;
    color:white;
    border-style: dashed;
    border-width: 6px;
    /* border-color: rgba(0,0,0,0); */
    border-radius: 30px;
    /* font-size: 18px; */
    outline-style: none;
    /* outline-width: 5px; */
    border-color: rgba(255, 255, 255, 1);
    opacity: .7;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center ;
    
}

.EmptyDocument:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, .3);

}

/*
.NoDocument {

    padding: 200px 110px 0px 60px;
    box-sizing: border-box;
    height:100%;
    resize: none;
    min-width: 700px;
    max-width: 700px;
    min-height: 70%;
    max-height: 70%;
    margin: 70px auto; 
    font-family:Arial, Helvetica, sans-serif ;
    font-size: 30px;
    color:white;
    border-style: dashed;
    border-width: 6px;
    border-radius: 30px;
    outline-style: none;
    border-color: rgba(255, 255, 255, 1);
    opacity: .5;
}
*/

.DocumentDiv{
  position: absolute;
  box-sizing: border-box;
  /* left:30px; */
  left: 210px;

  right:0px;
  top: 0px;
  bottom: 0px;
  transition: left, .1s;
}
.NoDocument {

  position: absolute;
  box-sizing: border-box;
  /* left: 240px; */
  left: 30px;
  right: 30px;
  top: 100px;
  bottom: 100px;
  resize: none;
  min-width: 100px;
  max-width: 900px;
  /* max-height: 900px; */

  margin: 0 auto; 

  font-family:Arial, Helvetica, sans-serif ;
  font-size: 30px;
  color:white;
  border-style: dashed;
  border-width: 6px;
  border-radius: 30px;
  outline-style: none;
  border-color: rgba(255, 255, 255, 1);
  opacity: .26;
  display: flex;
  justify-content: center;
  align-items: center ;
  
}



.tree_view_spacer{
  height:12px;
}