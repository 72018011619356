
.QuillEditorX {
    position: relative;
    /* left:0px;
    right:0px;
    bottom:0px;
    top:0px; */
    width:100%;
    height:calc(100% - 40px);
    box-sizing: border-box;

}



.QuillEditor {
  position: absolute;
    padding: 0px 0px 42px 0px;
    box-sizing: border-box;
    /* height:100%; */
    height:calc(100% - 60px);
    /* left: 220px; */
    left: 10px;
    right: 10px;
    top: 30px;
    bottom: 30px;
    resize: none;
    min-width: 700px;
    max-width: 800px;
    /* min-height: 100%; */
    /* max-height: 100%; */
    margin: 0 auto; 
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .2);
    /* font-family:Arial, Helvetica, sans-serif ; */
    /* border-style: none; */
    /* font-size: 15px; */
    outline-style: none;
    background-color: rgb(241, 241, 241);
    /* background-color: red;
    left:100px;
    top:100px;
    width:100%;
    height: 100%;
    position: fixed;
    z-index: 2000; */
}
.QuillEditor:focus {
    box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, .3);

}


/* width */
.QuillEditor::-webkit-scrollbar {
    width: 14px;

  
  } 
  
  /* Track */
  .QuillEditor::-webkit-scrollbar-track {
    background: rgba(0,0,0,.1); 
    border-radius: 0px;

  }
  
  .QuillEditor::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 12px rgba(0, 0, 0, .12); 
  } 
  
  /* Handle */
  .QuillEditor::-webkit-scrollbar-thumb {
    width:3px;
    border-radius: 4px;
    background: hsla(200, 0%, 100%, .4) ;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,.1); 
  }
  .QuillEditor::-webkit-scrollbar-thumb:hover {
    background: hsla(200, 0%, 100%, .6) ;
 
  }