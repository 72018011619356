/* .Checkbox_top_Div{
    position: relative;
    display: flex;
    padding: 4px;
    box-sizing: border-box;
    margin:6px;
    height:36px;
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif ;

    font-size: 16px;

} */
.Checkbox_top_Div {
    display:flex;
    box-sizing: border-box;
    /* text-align: left; */
    position: relative;
    height:34px;
    /* width: 100%; */
    /* margin:6px; */
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif ;
    /* font-size: 16px; */
    padding:5px 8px;
    border-radius: 4px;

    transition: 0.3s; 
    font-size: 18px;
    font-family: 'Open Sans Condensed', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
    margin:8px 0px;
    


}
/* .textFieldDiv {
    position: relative;
    transition: 0.3s; 
    font-size: 18px;
    font-family: 'Open Sans Condensed', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    z-index: 10;
    margin:8px 0px;
    display: flex;
    outline-style: none;
    
} */





.Checkbox_top_Div:hover {
    background-color: rgba(255, 255, 255, .1); 
}
.Checkbox_top_Div:active {
    background-color: rgba(255, 255, 255, .2); 
}


.CheckboxDiv{
    position: absolute;
    width:20px;
    height:20px;
    right:0px;
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;
    /* border-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: rgba(255, 255, 255, 0.4);
    color: hsla(200, 40%, 20%, 60%); */

    /* background-color: rgba(255, 255, 255, .3); */
    border-color: rgba(255, 255, 255, .3);
    /* border-radius: 4px; */
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);

}

.CheckboxDiv-dark{
    position: absolute;
    width:20px;
    height:20px;
    right:0px;
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;
    border-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
}




.CheckboxImage{
    margin:auto;
    color: hsla(200, 40%, 20%, 60%);
}

.checkBoxLabel{
    /* margin-bottom: 1px; */
    font-size: 18px;
    margin: -1px 0px 0px 15px;
}

.checkBoxLabel-dark{
    /* margin-bottom: 1px; */
    font-size: 18px;
    margin: -1px 0px 0px 15px;
    color:black;
}