.textFieldDiv {
    position: relative;
    transition: 0.3s; 
    font-size: 18px;
    font-family: 'Open Sans Condensed', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    z-index: 10;
    margin:8px 0px;
    display: flex;
    outline-style: none;
    
}

.textAreaDiv {
    position: relative;
    /* transition: all 0.3s, resize 0s; */
    transition: 0s;
    font-size: 18px;
    font-family: 'Open Sans Condensed', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    z-index: 10;
    margin:8px 0px;

    outline-style: none;

}


.InputLabel{

    width:104px;
    text-align: right;
    padding: 0px 5px 0px 0px;
}

.textArea{
    user-select: none; 

    resize: vertical;
    height:128px;
    width:100%;
    /* height:30px; */

    box-sizing: border-box;

    font-size: 16px;
    padding: 4px 4px 4px 8px;
    font-family: 'Rokkitt', serif;
    color: black;
    background-color: rgba(255, 255, 255, .3);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    /* transition-property: background-color, box-shadow; */
    /* transition: .2s; */
    /* transition: 0s; */

    transition: all 0s, background-color 0.0s, box-shadow 0.2s;
    
    border-style: none;
    scroll-behavior: auto;
    overflow-x: hidden; 
    outline-style: none;


}

.textArea:hover {
    /* transition-property: background-color, box-shadow; */
    background-color: rgba(255, 255, 255, .45);
    /* transition: .2s; */
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);

}
.textArea:focus { 
    background-color: rgba(255, 255, 255, .8);
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);
}



.textFieldInput {
    width:100%;
    height:25px;

    box-sizing: border-box;

    font-size: 22px;
    padding: 4px 4px 4px 8px;
    font-family: 'Rokkitt', serif;
    color: black;
    background-color: rgba(255, 255, 255, .3);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    /* transition-property: box-shadow; */
    transition: .2s, .2s, 0s;
    transition-property: background-color, box-shadow;
    resize: vertical;
    border-style: none;
    outline-style: none;

    resize: none;


}

.textFieldInput:hover {
    transition-property: background-color, box-shadow;
    background-color: rgba(255, 255, 255, .45);
    transition: .2s;
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);

}
.textFieldInput:focus { 
    background-color: rgba(255, 255, 255, .8);
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);
}



/* ---------  CARD UI -----------*/

/* 
.textAreaCard{
    user-select: none; 
    resize: none;
    overflow: hidden;
    height:160px;
    width:100%;

    box-sizing: border-box;

    font-size: 126px;
    padding: 5px 5px 5px 10px;
    font-family: 'Rokkitt', serif;
    color: red;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
    transition-property: background-color, box-shadow;
    transition: .2s;
    border-style: none;
    outline-style: none;


} 


.textAreaCard:hover {
    transition-property: background-color, box-shadow;
    background-color: rgba(255, 255, 255, .45);
    transition: .2s;

}
.textAreaCard:focus { 
    background-color: rgba(255, 255, 255, .8);
}


.textFieldInputCard {
    width:100%;
    height:30px;

    box-sizing: border-box;

    font-size: 206px;
    padding: 5px 5px 5px 10px;
    font-family: 'Rokkitt', serif;
    color: red;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
    transition-property: background-color, box-shadow;
    transition: .2s;
    resize: vertical;
    border-style: none;

    outline-style: none;


}

.textFieldInputCard:hover {
    transition-property: background-color, box-shadow;
    background-color: rgba(255, 255, 255, .45);
    transition: .2s;

}
.textFieldInputCard:focus { 
    background-color: rgba(255, 255, 255, .8);
}

*/
.Separator{
    width:80%;
    margin:9px auto;
    height:1px;
    background-color:rgba(0, 0, 0, .15); 
    
}

