.TimelineHudDiv{
    position: absolute;
    /* width: 100%;
    height:100%; */
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    /* width: 1px;
    height:1px; */
    transition: bottom .2s;
    pointer-events: none;

}
/* .TimelineHudDiv_top{
    position: absolute;
    width: 100%;
    height:100%;
    pointer-events: none;
} */
