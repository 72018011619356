/* .StoryItem {
    margin: 2px;
    width: 44px;
    padding: 2px 0 0 0;
    
} */


.ThumbnailDiv {

    width: 160px;
    /* height:160px; */
    margin:auto;
    border-style: solid;

    border-color: rgba(0, 0, 0, 0);
    border-radius: 12px;
    border-width: 4px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    overflow: hidden;

}

.ThumbnailDiv:hover {
    border-style: solid;
    background-color: rgba(255, 255, 255, .5);
    transition: .2s;
    box-shadow: 2px 8px 8px 0px rgba(0, 0, 0, .05);

}
.ThumbnailDiv:active {
    transition: 0s;
    background-color: rgba(255, 255, 255, 0.8);

}

.Thumbnail {
    object-fit: cover;
    width: 160px;
    height:160px;
    border-radius: 4px;
    /* padding:12px; */    
}
