
.MapImage{
    position: relative;
    top:-40px;
    /* left:100px; */
    margin:auto;
    transform: scale(.8);

}
.TimeLinePanel{
    position: absolute;
    width:100%;
    height:100%;

    /* background: linear-gradient(rgb(172, 169, 165), rgb(139, 135, 128));
    background-size: 30px 30px; */

    /* background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.08) 1px, transparent 1px), 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 1px, transparent 1px); */

}


.MapTimeLine{
    position: absolute;
    left:50px;
    bottom:60px;
    right:50px;
    height:40px;

    /* background-color: green; */
    background-size: 10px 100px;

    background-image: 
    linear-gradient(to right, rgba(0, 0, 255, 0.3) 1px, transparent 1px);
    border-style: solid;
    border-color: blue;
    border-radius: 6px;
}
.MapTimeCursor{
    background-color: red;
    position: absolute;
    height:100%;
    width:10px;
    

    /* linear-gradient(to bottom, rgba(0, 0, 255, 0.3) 1px, transparent 1px); */
}

.TimeIntField{
    color: red;
    /* width: 200px; */
    position: absolute;
    bottom:20px;
    left:450px;
}

.testMapCharacter{
    width:30px;
    height:30px;
    background-color: orange;
    border-radius: 15px ;
    transition: .25s;

}

.testMapCharacterB{
    width:120px;
    height:30px;
    background-color: orange;
    border-radius: 15px ;
    transition: .25s;

}