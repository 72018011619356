.itemContainer {

  /* background-color: rgba(0, 0, 0, 0.05); */
  /* border-color: rgba(0, 0, 0, .1); */
  border-radius: 3px;
  /* border-style: solid;  */
  border-style: none; 
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
  transition: flex-basis 500ms ease-in-out;
  overflow: hidden;
  background-color: rgba(255, 255, 255, .2);
  /* border-color: rgba(0, 0, 0, 0); */



  /* resize: vertical; */

}

.itemContainer-label { 

  transition: 0.3s; 
  font-size: 16px;
  font-family: 'Open Sans Condensed', sans-serif;
  color: rgba(255, 255, 255, 0.6);
  text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
}

.itemContainerLarge {


    background-color: rgba(0, 0, 0, 0.03);
    border-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    border-style: none; 
    box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, .15);
    transition: flex-basis 500ms ease-in-out;
    overflow: hidden;
  }
  .itemContainerLarge-label {
    /* display: fixed; */
    text-align: left;
    padding: 0px 24px 0px;

    width:100%;
    /* height:100px; */
    /* margin: 30px; */
    font-size: 26px;
    /* background-color: rgba(255, 0, 0, 0.3); */
    background-color: rgba(22, 36, 61, 0.1);
    color: white;
    transition:.2s;


  }

  .itemContainerLarge-label:hover {
    transition:.1s;
    background-color: rgba(34, 52, 90, 0.3);
    /* border-style: solid;  */

    /* background-color: rgba(37, 61, 141, 0.3); */
  }


  .itemContainer-flex {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    margin: auto;
    overflow: hidden;
    padding: 6px 4px;

    transition: flex-basis 500ms ease-in-out;
  }