.projectContainer {

  background-color: rgba(0, 0, 0, 0.05);
  /* border-color: rgba(0, 0, 0, .1); */
  border-radius: 3px;
  border-style: none; 
  /* border-style: none;  */
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
  transition: flex-basis 500ms ease-in-out;
  overflow: hidden;
  background-color: rgba(255, 255, 255, .2);
  border-color: rgba(0, 0, 0, 0);
  /* padding: 10px; */
  /* margin:20px; */
  /* font-family: 'Rokkitt', serif; */
  font-family: 'Open Sans Condensed', sans-serif;
  scroll-behavior: auto;
  overflow-x: hidden; /* Disable horizontal scroll */




  /* resize: vertical; */

}

.projectContainer-label { 

  transition: 0.3s; /* 0.5 second transition effect to slide in the sidenav */
  font-size: 16px;
  /* font-family: 'Open Sans Condensed', sans-serif; */
  /* font-family: 'Rokkitt', serif; */

  color: rgba(255, 255, 255, 0.6);
  text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
}

  .projectContainerLarge-label {
    /* display: fixed; */
    text-align: left;
    padding: 0px 24px 0px;

    width:100%;
    /* height:100px; */
    /* margin: 30px; */
    font-size: 26px;
    /* background-color: rgba(255, 0, 0, 0.3); */
    background-color: rgba(22, 36, 61, 0.1);
    color: white;
    transition:.2s;

  }

  .projectContainerLarge-label:hover {
    transition:.1s;
    background-color: rgba(34, 52, 90, 0.3);
    /* border-style: solid;  */

    /* background-color: rgba(37, 61, 141, 0.3); */
  }


  .projectContainer-flex {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    margin: auto;
    /* margin: 30px; */
    overflow: hidden;
    padding: 6px 4px;
    /* background-color: rgba(255, 0, 0, 0.3); */

    /* background-color: rgba(24, 25, 59, 0.2); */
    /* background-color: rgba(0, 0, 0, 0.1); */
    /* border-color: rgba(0, 0, 0, .1); */
    /* border-radius: 10px; */
    /* border-style: solid;  */
    /* box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, .05); */
    transition: flex-basis 500ms ease-in-out;

  }