:root {
    --attributePanel_width: 300px; 
}

.collapsedDiv {
    /* display: none; */
    position: fixed;
    right:0px;
    top:72px;
    height:120px;
    width:40px;

    background: linear-gradient(to top , hsl(200, 8%, 55%) ,hsl(200, 10%, 50%));


    border-radius: 8px 0px 0px 8px;

    box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, .5);
    font-size: 16px;
    cursor:col-resize;

}




.WidthHandle {
    position: absolute;
    /* position: fixed; */
    /* right:calc(var(--attributePanel_width) - 20px); */
    /* right:var(--attributePanel_width); */
    left:0px;
    top:0;
    height:100%;
    width:15px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    /* background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); */
    opacity: 0;
    transition: .1s;
    z-index: 200;
    /* box-shadow: -0px -0px 10px 0px rgba(0, 0, 0, .8); */
    cursor:col-resize;

}



.WidthHandle:hover {
    opacity:.45;
    transition: .02s;
}
.WidthHandle:active {
    opacity:.8;
    /* transition: .02s; */
    cursor:grabbing;

}

#WidthHandleCollapsed {
    position: absolute;
    /* position: fixed; */
    /* right:calc(var(--attributePanel_width) - 20px); */
    /* right:var(--attributePanel_width); */
    left:-6px;
    top:0;
    height:40px;
    width:10px;
    /* background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); */
    /* background: blue; */
    /* color: black; */
    /* opacity: .3; */
    font-size: 22px;
    padding:75px 0px 0px 12px;
    color: hsla(200, 40%, 20%, 60%);

    cursor:col-resize;

}
.WidthHandleCollapsed:active {
    opacity:.5;
    /* transition: .02s; */
    cursor:grabbing;

}


#widthIcon {
    color: hsla(200, 40%, 20%, 60%);
}
#MyAttributePanel {
    display:block;
    position: fixed;
    z-index: 1; /* Stay on top */
    top: 36px;
    bottom: 0px;
    right: 0px;
    /* transition: 0.15s;  */
    transition: .2s; 
    /* background: linear-gradient(to top right, hsl(200, 4%, 70%) ,hsl(200, 10%, 50%)); */
    background: linear-gradient(hsl(200, 4%, 70%) ,hsl(200, 5%, 66%));
    /* background: linear-gradient(hsl(0, 0%, 15%) ,hsl(0, 0%, 9%)); */
    /* background: hsl(200, 4%, 70%); */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);
    overflow-x: hidden; /* Disable horizontal scroll */

    /* background: linear-gradient(hsla(200, 4%, 70%, 0.7) ,hsl(200, 5%, 66%, 0.7));
    backdrop-filter: blur(5px); */

}

.AttrPanelScrollDiv{
    scroll-behavior: auto;
     position: absolute;
    z-index: 1; /* Stay on top */
    top:48px;
    bottom:0px;
    width:100%;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding: 0px 12px 24px 12px;
    box-sizing: border-box;


}


#backButton {
    position: absolute;
    top:20px;
    left:18px;
    font-size: 16px;
    color: hsla(200, 40%, 20%, 60%);
    cursor: pointer;
}
#backButton:hover{
    color: hsla(200, 50%, 95%, 60%);
}
#backButton:active {
    transition: 0s;
    color: white;

}


#forwardButton {
    position: absolute;
    top:20px;
    left:42px;
    font-size: 16px;
    color: hsla(200, 40%, 20%, 60%);

    cursor: pointer;
}
#forwardButton:hover{
    color: hsla(200, 50%, 95%, 60%);
}
#forwardButton:active {
    transition: 0s;
    color: white;

}


#optionsButton {
    position: absolute;
    top:20px;
    right:16px;
    font-size: 16px;
    color: hsla(200, 40%, 20%, 60%);

    cursor: pointer;
}
#optionsButton:hover{
    color: hsla(200, 50%, 95%, 60%);
}
#optionsButton:active {
    transition: 0s;
    color: white;

}



.AttrPanelHeader {
    position: relative;
    top: 0px;
    width:100%;
    height:32px;
    bottom: 0px;
    right: 0;
    /* background-color: rgb(8, 43, 88); */
    padding:12px 0px 0px 0px;
    box-sizing: border-box;
    font-size: 23px;
    font-family: 'Open Sans Condensed', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);

}


  #HeaderSeparator{
      position:relative;
      top:-6px;
  }

  .attributeFrame{
      /* height:0px; */
      overflow: hidden;
      /* background-color: orange; */
      margin:6px 0px;
      padding:4px 10px;
      border-radius: 4px;
      border-radius: 3px;
      /* border-style: solid;  */
      border-style: none; 
      box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
      /* background-color: rgba(255, 255, 255, .2); */
      border-style: solid;
      border-width: 1px;
      border-color: rgba(255, 255, 255, .2);
      border-color:rgba(0, 0, 0, .15); 

      /* background-color:rgba(0, 0, 0, .15);  */

  }