

.TestingPageDiv {
    position: absolute;
    width:100%;
    height:100%;
    background-color: lightpink;
    /* background: linear-gradient(to top right, hsl(200, 4%, 70%) ,hsl(200, 10%, 50%)); */
    /* text-align: center; */

}

.test-flex-parent {
    position: relative;
    /* left: 30px;
    top: 60px;
    width: 50%; */
    /* height: 500px; */
    /* display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap; */
    margin: auto;
    overflow: hidden;
    /* padding: 6px 4px; */
    /* background-color: grey; */
    /* gap: 0px; */
    /* transition: 1s; */



    /* transition: flex-basis 500ms ease-in-out; */
  }
.test-flex {
    position: relative;
    /* left: 30px;
    top: 60px;
    width: 50%; */
    /* height: 500px; */
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    /* margin: auto; */
    overflow: hidden;
    /* padding: 6px 4px; */
    /* background-color: grey; */
    gap: 20px;
    transition: 1s;
    padding:14px;
    /* margin:14px; */
    box-sizing: border-box;

    /* transition: flex-basis 500ms ease-in-out; */
  }
.test-flex-display {
    position: relative;
    left: 30px;
    top: 60px;
    width: 50%;
    /* height: 500px; */
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    margin: auto;
    overflow: hidden;
    padding: 6px 4px;
    background-color: grey;
    gap: 0px;
    /* transition: 1s; */


    /* transition: flex-basis 500ms ease-in-out; */
  }
.test_item{
    position: relative;
    background-color: mediumvioletred;
    /* height: 50px;
    width: 50px; */
    /* transition: .3s; */
    user-select: none; /* Standard */
    border-radius: 6px;
    outline: 2px rgba(76, 0, 130, 0.294);
    outline-style: solid;
    outline-offset: -3px;
    /* margin:2px; */
    /* box-sizing: border-box; */
    line-height: 50px;
    /* margin: auto; */
    text-align: center;
    /* padding: 4px; */
}
.item_hover:hover{
    background-color: rgb(255, 113, 203);

}

.dragging_cursor{
  cursor: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/happy.png"), auto;
}


.test_button{
    width:300px;
    position: relative;
}

.test_item_dragging{
  position: fixed;
   background-color: white;
   z-index: 100;
   height: 50px;
   width: 50px;
   pointer-events: none;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, .3);
   border-radius: 6px;

  }
/* .test_item_dragging ::{
  position: relative;
   background-color: green;
   z-index: -9;
   height: 53px;
   width: 55px;
   pointer-events: none;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, .3);
   border-radius: 6px;
   content: 'test';

  } */

  .dragging_badge{
    /* background-color: crimson; */
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    position: absolute;
    right:-10px;
    top:-10px;
    width:20px;
    height:20px;
    line-height: 20px;
    margin: auto;
    text-align: center;
    font-size: 12px;
    font-weight: bolder;
    z-index: 100;
  }

.flex_selection_box{
    position: fixed;
    outline-style: dotted;
    outline-color: white;
    left: 300px;
    top: 300px;
    width:1000px;
    height:700px;
    background-color: rgba(255, 255, 255, .25);
    /* box-sizing: border-box; */
    z-index: 10;
}

.flex_bbox_vis{
    position: fixed;
    /* outline-style: dotted;
    outline-color: white; */
    /* position: fixed;
    left: 300px;
    top: 300px;
    width:1000px;
    height:700px; */
    background-color: rgba(218, 102, 102, 0.25);
    box-sizing: border-box;
    border-style: solid;
    border-color: springgreen;
    pointer-events: none;
  }

  /* .test_container_parent{
    top:30px;
    margin: 20px auto ;
    position:relative;
    width:60%;
    box-sizing: border-box;
 
  }
 */


  /* .flex-drop-target-move{
    position: absolute;
    left: calc(50% + 2px);
    right: 5px;
    top:5px;
    bottom:5px;
    border-radius: 10px;
    border-style: dashed;
    box-sizing: border-box;
    border-color: black;
    background-color: rgba(136, 136, 136, 0.5);

    border-color: rgba(255, 255, 255, 0.5);
    border-width: 3px;
    color: rgba(255, 255, 255, 0.5);;
    font-size: 30px;

    vertical-align: middle;
    display: flex;
    align-items:center;
    justify-content:center;

    backdrop-filter: blur(3px);

  
  }

  .flex-drop-target-move:hover{
    background-color: rgba(207, 207, 207, 0.63);
    border-color: white;
    color: white;

  }
  .flex-drop-target-copy{
    position: absolute;
    left: 5px;
    right: calc(50% + 2px);
    top:5px;
    bottom:5px;
    border-radius: 10px;
    border-style: dashed;
    box-sizing: border-box;
    border-color: black;
    background-color: rgba(136, 136, 136, 0.5);

    border-color: rgba(255, 255, 255, 0.5);
    border-width: 3px;
    color: rgba(255, 255, 255, 0.5);;
    font-size: 30px;
    vertical-align: middle;
    display: flex;
    align-items:center;
    justify-content:center;

    backdrop-filter: blur(3px);

  }
  .flex-drop-target-copy:hover{
    background-color: rgba(207, 207, 207, 0.63);
    border-color: white;
    color: white;

  } */