.TestingPanel {
    position: fixed;
    top: 100px;
    left:120px;
    /* background-color:orange; */
    /* background: linear-gradient(rgb(219, 216, 211), rgb(187, 181, 172)); */
    background-color: lightcoral;
    width:600px;
    height:500px;
    z-index: 100;
    opacity:1;
    padding:10px 60px;
    box-sizing: border-box;

}
