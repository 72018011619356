.time_event_label{
    position: absolute;
    background-color: hotpink;
    border-radius: 11px;
    width:150px;
    left:-75px;
    height:20px;
    font-size: 16px;
    border-style: solid;
    border-color: rgba(0,0,0,0);
    border-width: 2px;
    bottom:100%;
    /* box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, .1); */
    cursor: ew-resize;
    pointer-events: all;


}
.time_event_label:hover{
    opacity:.7;
    /* box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, .1); */
}

.eventSelected{
    border-style: solid;
    border-color: white;
    border-width: 2px;
}
.timeEventDiv{
        position: absolute;
        /* height: calc(100% - 20px) ; */
        height: 100%;
        top:24px;
        width:6px;
        left:-83px;
        background-color: hotpink;
        /* opacity:.5; */
        /* margin:0px 0px 0px -2px; */
        /* margin:100px; */
    
        /* cursor: ew-resize; */
        transition: top .07s;
        pointer-events: none;

    }
    
/* .timeEventDiv:hover{
        opacity:.7;
    }
     */

#eventLockIcon{
    position: absolute;
    right:6px;
    top:4px;
    color: black;
    opacity: .5;
    font-size: 10px;
}