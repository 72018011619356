.Attribute_div{
    display:flex;
    box-sizing: border-box;
    text-align: right;
    position: relative;
    /* height:34px; */
    /* margin:6px; */
    box-sizing: border-box;
    /* font-family:Arial, Helvetica, sans-serif ; */
    /* font-size: 16px; */
    /* padding:4px; */
    border-radius: 4px;
    width:100%;
    /* left:0px;
    right:20px; */
    height:34px;

}


.Attribute_div:hover {
    background-color: rgba(255, 255, 255, .1); 
}



.keyframeButtonX{
    box-sizing: border-box;
    right:0px;
    position: relative;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 10px;
    height:20px;
    width:20px;
    font-size: 16px;
    margin:10px 6px 0px 6px;   
    color: rgba(255, 255, 255, 0);
    padding:2px;
    /* opacity:.2; */
    cursor: pointer;
    
}
.keyframeButtonX:hover{
    /* opacity:.6; */
    color: rgba(255, 255, 255, .6);

}
.keyframeButtonX:active{
    /* opacity:.6; */
    color: rgba(255, 255, 255, 1);
    /* background-color: rgba(255, 255, 255, .5); */


}

.KeyButtonHasKeys{
    /* background-color: rgba(255, 255, 255, .5); */

    color: rgba(255, 255, 255, 1);
    /* background-color: rgba(255, 255, 255, .4); */
    /* background-color: orange */

    /* color: orange; */

}
/* .KeyButtonHasKeys:hover{
    background-color: rgba(255, 255, 255, .6);
    color: orange;
}
.KeyButtonHasKeys:active{
    background-color: rgba(255, 255, 255, .6);
    color: orange;
}
*/
.KeyButtonOnKeyTime{
     color: rgba(255, 255, 255, 1);
     /*
    background-color: orange; */
    /* color: rgba(255, 255, 255, 1);
    background-color: orange;
    color: rgb(255, 193, 78);
    background-color: white;
    color: orange; */
    /* font-size:18px;
    padding:1px; */
} 



.AttrHasKeys{
    /* background-color: rgba(255, 255, 255, .5); */

    /* background-color: rgb(211, 212, 129); */
    background-color: rgb(212, 190, 129) !important;
    transition: background-color 0s !important; 

}
.AttrHasKeys:hover{
    background-color: rgb(236, 193, 118) !important;
}
.AttrHasKeys:focus{
    background-color: rgb(255, 210, 132) !important;
}

.AttrOnKeyTime{
    /* color: rgba(255, 255, 255, 1);
    background-color: orange; */
    /* background-color: rgb(200, 168, 117); */
    background-color: rgb(236, 171, 49) !important;
    transition: background-color 0s !important; 
} 

.AttrOnKeyTime:hover{
    background-color: rgb(255, 181, 43) !important;
}
.AttrOnKeyTime:focus{
    background-color: rgb(255, 201, 102) !important;
    background-color: orange !important;

}