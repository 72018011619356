.App {
  text-align: center;
  /* word-wrap: none; */
  user-select: none; /* Non-prefixed version, currently*/

  overflow-x: hidden; 
}

.DisablPointer{
  pointer-events: none;
}

.App_cursor_e_resize{
  cursor: e-resize !important;
}

.testtt{
  position: fixed;
  left:300px;
  top:300px;
  width:10px;
  height:10px;
  font-size:50px;
  color: green;
  background-color: blue;
  z-index: 100;
  pointer-events: none;
}

/* .App {
  position: absolute;
  text-align: center;
  /* word-wrap: none; 
  user-select: none; 
  height:100%;
  width:100%;
  background-color: yellow;
} */
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.LoadingScreen {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .6);
  /* opacity: .3; */
  z-index: 10;
}
.LoadingLogo {
  position: relative;
  top:40%;
  margin:auto;
  color: aliceblue;
  font-size:50px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  /* border-radius: 4px; */

} 

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0); 
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .12); 
} 

/* Handle */
::-webkit-scrollbar-thumb {
  width:5px;
  border-radius: 4px;
  background: hsla(200, 0%, 100%, .2) ;
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,.1); 
  /* border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 255, 255, .5); */

}
::-webkit-scrollbar-thumb:hover {
  background: hsla(200, 0%, 100%, .6) ;

}

::-webkit-scrollbar-corner
{
  background: rgba(0,0,0,0); 
  /* border-radius: 4px; */
  border-style:none; 
  outline: none;
}