.writingCard_div {
    position: relative;
    margin: 4px 0px;
    /* background-color: grey; */
    width:150px;
    padding: 1px 2px 2px 2px;

}
.writingCard {
    /* position: absolute; */
    position: relative;
    padding: 1px 2px 2px 2px;
    /* margin: 10px 0px; */
    box-sizing: border-box;
    height:70px;
    /* width:140px; */
    width:100%;
    background-color: rgb(238, 225, 169);

    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, .3);
    font-family:Arial, Helvetica, sans-serif ;

    border-style: none;
    border-radius: 4px;
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 4px;

    resize: none;
    /* font-size: 14px; */

    /* user-select: none;  */

    cursor: pointer;

}

.card_children_div{
    position: relative;
    /* background-color: coral; */
    width:200%;
    /* height:20px; */
    /* height:0px; */
    /* height:100%; */
    height: fit-content;
    /* height: 150px; */
    /* position: absolute; */
    transition: .2s;
    top:100%;
    overflow: hidden;
    box-sizing: border-box;
    margin-left: 14px;

}

.WritingCardTitle {
    position: relative;

    font-family: 'Rokkitt', serif;

    /* text-align: center; */
    left:5px;
    /* right:50px; */
    font-size: 16px;
    width:100px;
    height: 50px;

    color: black;
    background-color: rgba(0,0,0,0);
    border-style: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/

    outline: none;     
    pointer-events: none;
    border-radius: 3px;
    resize: none;

}

#Writer-DocumentIcon {
    position: absolute;
    top:-2px;
    left:6px;
    font-size: 20px;
    color:white;
}

#Writer-DocumentIconShad {
    position: absolute;
    top:2px;
    left:2px;
    font-size: 20px;
    color:black;
    opacity:.1;
}

.test_child{
    position: relative;
    height:40px;
    width:85%;
    
    background-color: goldenrod;
    margin: 4px 10px;
    box-sizing: border-box;

}





.NestedCardCounter_writing {
    position: absolute;
    top:-10px;
    right:-8px;
    width: 32px;
    height:32px;

    border-radius: 50%;
    border-color: white;

    border-width: 4px;

    font-family:Arial, Helvetica, sans-serif, ;

    padding: 6px 0px;
    box-sizing: border-box;

    font-size: 19px;

    box-shadow: -2px 6px 0px 0px rgba(0, 0, 0, .1);
    cursor: alias;
    cursor: zoom-in;
    font-weight: bold;
    color: rgb(255, 2555, 255);
    background-color: rgb(124, 174, 255);
    z-index: 100;
}
/* .NestedCardCounter_writing:hover {
    box-shadow: -4px 9px 0px 0px rgba(0, 0, 0, .9);
   
   } */

.NestedCardCounter_writing_over{
    z-index: 101;

    opacity:0;
    position: absolute;
    top:-10px;
    right:-8px;
    width: 32px;
    height:32px;

    border-radius: 50%;
    border-color: white;

    font-family:Arial, Helvetica, sans-serif, ;

    padding: 3px 0px;
    box-sizing: border-box;

    font-size: 23px;
    cursor: pointer;
    font-weight: bold;
    color: rgb(255, 2555, 255);
    background-color: rgb(206, 147, 71);
    background-color: rgb(88, 152, 255);
    background-color:white;
    color:rgb(88, 152, 255);
}
.NestedCardCounter_writing_over:hover {
 opacity: 1;
 box-shadow: -2px 6px 0px 0px rgba(0, 0, 0, .1);

}
.NestedCardCounter_writing_over:active {
    /* opacity: 1; */
    /* box-shadow: -1px 2px 0px 0px rgba(0, 0, 0, .1); */
    background-color:rgb(240, 240, 240);

   }
   
/* .NestedCardCounter_writing:hover {
    box-shadow: -4px 9px 0px 0px rgba(0, 0, 0, .9);
   
   } */