.ProgressBarOuterDiv{
    /* width:100%; */
    height:30px;
    background-color: rgba(0, 0, 0, 0.1);
    /* opacity: .2; */
    /* border-style: solid; */
    border-radius: 6px;
    overflow:hidden;
    /* padding:4px; */
    margin: 10px;
    box-sizing: border-box;
    box-shadow: inset 1px 4px 6px 1px rgba(0, 0, 0, 0.08);
}

.ProgressBarInnerDiv{
    width:100%;
    height:100%;
    background-color: hsl(190, 8%, 78%);
    /* opacity: .5; */

    /* border-style: solid; */
    /* border-radius: 10px; */
    transition: .3s;
}

.ProgressBarText{
    /* position: absolute; */
    width:100%;
    height:100%;
    /* font-size: 30px; */
    color: red;
}