.MapHudDiv{
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    pointer-events: none;
    transition: bottom .2s;
}

/* .TimeLineDiv{
    pointer-events: all;

} */