.ProjectsPanel {
    position: fixed;

    /* background-color:orange; */
    background: linear-gradient(rgb(219, 216, 211), rgb(187, 181, 172));

    width:100%;
    height:100%;
    z-index: 10;
    opacity:1;
    padding:10px 60px 90px;
    box-sizing: border-box;
    overflow-y:auto;


}
    /* .LoadingScreen {
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: .3;
        z-index: 10;
      }
} */

