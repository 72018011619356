

.TimeLineControlDiv{
    pointer-events: all;
    position: absolute;
    width: 100%;
    /* height:100%; */
    /* width: 1px;
    /* background-color: violet; */
    height:130px;
    bottom:0px;
    /* right:10px; */
    /* left:0px; */
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, .2);
    /* background-color: rgba(255, 255, 255, .15); */
    /* background-color: hsla(200, 10%, 50%, .5); */
    padding:30px;


    /* background: linear-gradient(to top , hsl(199, 13%, 67%) ,hsl(201, 15%, 59%)); */
    /* box-shadow: 3px 0px 20px 2px rgba(0, 0, 0, .5); */
    background-clip: border-box;
    transition: .2s;
    overflow:hidden;

    /* backdrop-filter: blur(5px); */



}


.TimeLineControl{
    position: relative;
    /* left:50px; */
    /* bottom:100px; */
    /* right:50px; */
    height:50px;
    width:100%;
    /* left:0px;
    right:0px; */
    top:0px;
    box-sizing: border-box;
    /* background-color: green; */
    background-size: 10px 100px;

    background-image: 
    linear-gradient(to right, rgba(0, 0, 255, 0.3) 1px, transparent 1px);
    border-style: solid;
    border-color: rgba(0, 0, 0, .5);
    border-radius: 6px;
    pointer-events: all;
    border-width: 2px;
    overflow:hidden;
    /* padding:4px 20px; */

}
.TimeCursor{
    background-color: orangered;
    opacity: .5;
    position: absolute;
    height:100%;
    width:8px;
    /* margin:0px 0px 0px -3px; */
    

    /* linear-gradient(to bottom, rgba(0, 0, 255, 0.3) 1px, transparent 1px); */
}