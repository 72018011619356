
.ThreadListItem {
    display:flex;
    box-sizing: border-box;
    /* align-items: right; */
    text-align: right;
    position: relative;
    /* height:1px; */
    height:34px;
    /* width:100%; */
    /* width:20px; */
    /* border-style: solid;
    border-color: white;
    border-radius: 6px;
    background-color: red;  */
    margin:6px;
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif ;

    font-size: 16px;
    /* transition:.2s; */
    /* display: inline; */
    /* z-index: 1000; */
    pointer-events: all;
    padding:6px;
    border-radius: 4px;

}

.ThreadListItem:hover {
    /* opacity: 1; */
    /* color:greenyellow;  */

    background-color: rgba(255, 255, 255, .4); 

}

.ThreadListItemSwatch {
    position: absolute;
    /* height:1px; */
    height:24px;
    right:5px;
    /* width:100%; */
    width:40px;
    top:5px;
    /* border-style: solid;
    border-color: rgba(255, 255, 255, 0);
    border-width: 3px; */

    border-radius: 6px;
    background-color: red; 
    /* margin:6px; */
    /* box-sizing: border-box; */
    /* font-family:Arial, Helvetica, sans-serif ; */

    /* font-size: 16px; */
    transition:.1s;
    /* display: inline; */
}