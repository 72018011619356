.sliderDiv{
    position: relative;
    width:100%;
    margin: 0px 10px;


    height:25px;
    box-sizing: border-box;

    /* padding: 4px 4px 4px 8px; */
    background-color: rgba(255, 255, 255, .3);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    transition-property: background-color, box-shadow;
    transition: .2s;
    border-style: none;
    outline-style: none;   
    overflow: hidden;
}
.sliderDiv:hover {
    transition-property: background-color, box-shadow;
    background-color: rgba(255, 255, 255, .45);
    transition: .2s;
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);

}
.sliderDiv:focus { 
    background-color: rgba(255, 255, 255, .8);
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);
}

.Slider{
    background-color: rgba(0, 0, 0, 0);
    /* color: greenyellow; */
    width:100%;
    /* background: #4CAF50; */
    -webkit-appearance: none;
    appearance: none;

    /* height:25px; */
    box-sizing: border-box;

    /* padding: 4px 4px 4px 8px; */
    /* background-color: rgba(255, 255, 255, .3);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    transition-property: background-color, box-shadow;
    transition: .2s;
    border-style: none;
    outline-style: none; */
    /* margin: 0px 10px; */

}

.sliderBar{
    position: absolute;
    /* background-color: royalblue; */
    background-color: rgba(22, 37, 43, 0.6);
    background-color: rgba(54, 101, 133, 0.6);

    width:90%;
    height:100%;
    top:0px;
    pointer-events: none;
    /* clip-path: hidden; */
}

.sliderBarKeyed{
    background-color: rgba(133, 128, 54, 0.6);
}


.Slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1px;
    height: 25px;
    border-radius: 50%; 
    background: #00000000;
    cursor: pointer;
    /* clip-rule: ; */
  }


.sliderFieldInput {
    width:50px;
    height:25px;
    right:0px;
    box-sizing: border-box;

    font-size: 22px;
    padding: 4px 4px 4px 8px;
    font-family: 'Rokkitt', serif;
    color: black;
    background-color: rgba(255, 255, 255, .3);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 4px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    transition-property: background-color, box-shadow;
    transition: .2s;
    border-style: none;
    outline-style: none;
}
.sliderFieldInput:hover {
    transition-property: background-color, box-shadow;
    background-color: rgba(255, 255, 255, .45);
    transition: .2s;
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);

}
.sliderFieldInput:focus { 
    background-color: rgba(255, 255, 255, .8);
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);
}





.sliderFieldInput::-webkit-outer-spin-button,
.sliderFieldInput::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.sliderFieldInput[type=number] {
    -moz-appearance:textfield; /* Firefox */
}