.ViewportParent{
    position: absolute;
    width:100%;
    height:100%;
}
.vpFullScreenParent{
    position: fixed;
    width:100%;
    height:100%;
    background-color: red;
}
.ViewportPanel{
    position: absolute;
    width:100%;
    height:100%;
    /* padding: 100px 0px 50px 0px; */
    /* box-sizing: border-box; */
    /* background: linear-gradient(rgb(219, 216, 211), rgb(187, 181, 172)); */
    /* background: linear-gradient(rgb(172, 169, 165), rgb(139, 135, 128)); */
    background-size: 30px 30px;

    
    /* background-clip: unset; */
    /* background-position:  0px 0px; */
    /* background-position-x:  */
    background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.08) 1px, transparent 1px), 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 1px, transparent 1px);

    /* background-image: 
    linear-gradient(to right, rgba(0, 0, 255, 0.08) 3px, transparent 1px), 
    linear-gradient(to bottom, rgba(0, 0, 255, 0.08) 3px, transparent 1px); */
    /* overflow:auto; */


}
.Viewport {
    transform-origin: 0% 0%;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    /* background-color: red; */
    /* position: fixed; */
    position: absolute;
    /* transform: scale(20, 5), */
    /* transform: rotate(0deg)  scale(3, 3); */
    /* overflow:auto; */

}


#vp_cursor {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: purple;
    
    /* position: fixed; */
    z-index: 30;
    pointer-events: none;
}
 /* #cardA{
    position: absolute;
    left:60px;
    top:50px;
}
#cardB{
    position: absolute;
    left:70px;
    top:300px;
}
#cardC{
    position: absolute;
    left:70px;
    top:300px;
}
 */
