.OverlayButtonDiv {
    position: absolute;
    z-index: 10;
    /* color: white; */
    color: rgb(240, 240, 240);
    opacity: .8;
    font-size: 29px;
    /* transition: .2s; */
    cursor:pointer;
    /* pointer-events: all; */


}

.OverlayButtonDiv:hover {
    opacity: 1;
    color:white;
    cursor:pointer;


}
.ButtonDiv {
    position: relative;
    width:100%;
    z-index: 10;
    /* pointer-events: all; */
    
}

.Button
{
    /* position: absolute; */
    position: relative;
    width:100%;
    /* z-index: 10; */

    font-family: 'Open Sans Condensed', sans-serif;

    /* height: 100%; */
    /* width: 100%; */
    font-size: 20px;
    color:white;

    background-color: rgba(255, 255, 255, .2);
    /* border-color: rgba(0, 0, 0, .1); */
    border-style:none;
    border-radius: 3px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    transition: .2s;
    transition-property: background-color, box-shadow;
    /* border: none; */
    cursor:'pointer';
    /* pointer-events: all; */
}


.Button:hover {
    transition-property: background-color, box-shadow;
    background-color: rgba(255, 255, 255, .45);
    transition: .2s;
    box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, .05);

}
.Button:active { 
    background-color: rgba(255, 255, 255, .8);
    box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, .05);
}




.ButtonDashed
{
    position: relative;
    width:80%;
    font-family: 'Open Sans Condensed', sans-serif;

    font-size: 20px;
    color:white;

    background-color: rgba(255, 255, 255, 0);
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-style:dashed;

    border-radius: 3px;
    /* box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05); */
    /* transition: .2s; */
    transition-property: background-color, box-shadow;
    cursor:'pointer';
}
.ButtonDashed:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px dashed rgba(255, 255, 255, .6);

}
.ButtonDashed:active { 
    background-color: rgba(255, 255, 255, 0.3);
    border: 2px dashed rgba(255, 255, 255, .6);
}




.CloseButtonDiv {
    /* overflow-x: hidden;  */
    position: absolute;
    /* margin: 10px; */
    right: 6px;
    top: 4px;
    width: 30px;
    height: 30px;
    /* width:100%; */
    /* display: table; */
    /* box-sizing: border-box; */
    z-index: 10;
    /* cursor:'pointer'; */
    /* pointer-events: all; */

}
.CloseButton
{
    position: absolute;
    color:white; 
    background-color: rgba(0, 0, 0, 0);
    font-size: 24px; 
    border: none;

    height: 100%; 
    width: 100%;
    opacity: 0.3;
    cursor:'pointer';
    /* pointer-events: all; */


}

.CloseButton:hover {
    opacity: 1;
}
