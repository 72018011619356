


/* select[multiple]:focus option:checked { */
.ListDiv:checked {
    /* background: red linear-gradient(0deg, orange 0%, orange 100%); */
    background: red linear-gradient(0deg, hsl(200, 25%, 66%) 0%, hsl(200, 25%, 66%) 100%);
    /* background: linear-gradient(0deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, .8) 100%); */
  }
/* .ListDiv:focus option:checked {
    background: red linear-gradient(0deg, hsl(200, 25%, 66%) 0%, hsl(200, 25%, 66%) 100%);
  } */
/* .ListDiv:focus option:checked:hover {
    background: red linear-gradient(0deg, yellow 0%, orange 100%);
  } */

.listItem{
    border-radius: 4px;
    padding:6px 16px;
    box-sizing: border-box;
    margin:2px;
    font-size: 16px;

}
.listItem:hover{
    background-color: rgba(255, 255, 255, .2);
}
.listItemSpecial{
    /* font-weight:bold; */
    /* font-style: italic; */
    font-size: 20px;
    /* color:white; */
    /* background-color: rgba(255, 255, 255, .2); */

}
/* 
.ListSelected {
    background-color: rgba(255, 255, 255, .6);
}
.ListSelected:hover {
    background-color: rgba(255, 255, 255, .6);
} */
/* .listTopDiv */
.ListDiv{
    scroll-behavior: auto;
     position: relative;
    z-index: 1; /* Stay on top */
    width:100%;
    /* height:100%; */
    top:0px;

    bottom:0px;
    /* right:0px; */
    /* height:200px; */
    /* margin:0px 6px; */
    /* padding: 4px 4px 4px 8px; */
    /* resize: vertical; */

    overflow-x: hidden; /* Disable horizontal scroll */
    /* padding: 0px 12px 24px 12px; */
    box-sizing: border-box;
    text-align: left;

    /* font-size: 16px; */
    font-family: 'Rokkitt', serif;
    color: black;
    background-color: rgba(255, 255, 255, .2);
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);

    border-style: none;
    border-width: 2px;
    border-radius: 4px;

    outline-style: none;
    transition: all 0s, background-color 0.2s, box-shadow 0.2s;

}
.ListDiv:hover {
    /* transition-property: background-color, box-shadow; */
    /* background-color: rgba(255, 255, 255, .45); */
    /* transition: .2s; */
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);

}

