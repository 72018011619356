.SelectMenuX{

    user-select: none; 
    position: relative;
    box-sizing: border-box;

    /* font-size: 16px; */
    font-size: 22px;
    padding: 1px 2px 4px 4px;
    font-family: 'Rokkitt', serif;
    color: black;
    background-color: rgba(255, 255, 255, .3);
    /* border-color: rgba(0, 0, 0, .1); */
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    /* transition-property: background-color, box-shadow; */
    /* transition: .2s; */
    /* transition: 0s; */

    transition: all 0s, background-color 0.2s, box-shadow 0.2s;
    
    border-style: none;
    border-width: 2px;
    border-radius: 4px;
    /* scroll-behavior: auto; */
    /* overflow-x: hidden;  */
    outline-style: none;
    width:100%;
    margin:0px 0px 0px 6px;
}

.SelectMenuX:hover {
    /* transition-property: background-color, box-shadow; */
    background-color: rgba(255, 255, 255, .45);
    /* transition: .2s; */
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);

}
.SelectMenuX:focus { 
    background-color: rgba(255, 255, 255, .8);
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);
}


.SelectMenuDivX {
    height:28px;
    left:0px;
    top:0px;
    /* position: absolute; */
    /* transition: 0.3s;  */
    font-size: 18px;
    font-family: 'Open Sans Condensed', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    z-index: 10;
    margin:6px 0px;
    display: flex;

    outline-style: none;
    width:100%;
    
}
/* .SelectMenuDiv:hover {
    background-color: rgba(255, 255, 255, .2);
} */

.SelectMenuLabelX{
    position: relative;
    /* height:100%; */
    top:-18px;
    width:104px;
    text-align: right;
    /* padding: 0px 5px 0px 0px; */
}


.SelectMenuHasKeys{
    /* background-color: rgba(255, 255, 255, .5); */
    /* border-style: solid; */
    /* border-color: orange; */
    background-color: rgba(255, 166, 0, 0.4);


    /* color: rgba(255, 255, 255, 1); */
}
/* .SelectMenuHasKeys:hover{
    background-color: rgba(255, 255, 255, .6);
    color: orange;
} */

.SelectMenuOnKeyTime{
    /* color: rgba(255, 255, 255, 1); */
    /* border-style: solid; */
    border-color: orange;
    background-color: orange;
}