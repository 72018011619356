.addItemButton_BG{
    position: absolute;
    /* left:0px;
    top:0px;
    right:0px;
    bottom:0px; */
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0);

    pointer-events: none;
    /* display:none; */
    transition:.1s;

}
.addItemButton_BTN{
    position: absolute;
    width:70px;
    height:70px;
    right:40px;
    bottom:40px;
    background-color: hsl(200, 8%, 65%);
    /* background: linear-gradient(to top right, hsl(200, 6%, 60%) ,hsl(200, 8%, 50%)); */
    color: white;
    box-shadow: -2px 3px 6px 0px rgba(0, 0, 0, .3 );
    border-radius: 50%;
    pointer-events: all;
    font-size: 65px;
    /* transition: .1s; */
    transition: .1s, bottom .2s;
    cursor:pointer;
    /* opacity: .5; */

}
.addItemButton_BTN:hover{
    box-shadow: -3px 5px 10px 0px rgba(0, 0, 0, .15);
    background-color: hsl(200, 6%, 70%);


}

.additem_test {
    left:0px;
    right:0px;
    height:60px;
    /* background-color: rgba(0, 0, 0, .1); */
    padding: 10px 10px;
    margin: 16px;
    font-size: 28px;
    border-radius: 20px;
    /* box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .1); */
    box-sizing: border-box;
    /* opacity: .8; */
    color: rgba(0, 0, 0, .7);
    box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, .15);
    cursor:pointer;

    color: white;
    /* display: inline-block; */
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .4);


}
.additem_test:hover{
    box-shadow: 1px 8px 10px 1px rgba(0, 0, 0, .1);
    /* background-color: rgba(255, 255, 255, .1); */
    opacity: 1;
}

.add_item_children_div {
    position: absolute;
    box-sizing: border-box;

    width:260px;
    height:200px;
    opacity:.8;
    right:16px;
    bottom:40px;
    border-radius:15px;
    /* font-family: 'Suranna', serif; */
    font-family: 'Open Sans Condensed', sans-serif;
    transition: .1s;


}