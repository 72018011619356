.SVG_BG {
    position:   absolute;
    left:0;
    top:0;
    z-index: 100;
    z-index: 2000;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; */
    user-select: none;
    pointer-events: none;
    padding:0px;
    margin:0px;
    /* opacity: .4; */
    overflow: visible;
    color:green;
}


.Thread_Path{
    position: absolute;
    /* z-index: -100;
    z-index: 2000; */
    opacity: .3;
    /* overflow: visible; */
    pointer-events: none;
    /* pointer-events: all; */
    color:green;
    stroke-dasharray:8 2;
    /* display: none; */


}
.Thread_Selector{
    position: absolute;
    /* z-index: -100; */
    z-index: 100;
    opacity: .0;
    overflow: visible;
    pointer-events: stroke;
    /* pointer-events: none; */
    /* display: none; */
    pointer-events: all;
    /* pointer-events: fill; */
}

/* .Thread_Selector:hover{
    opacity: .2;

} */