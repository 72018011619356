.FilePickerDiv {
    position: relative;
    width:100%;
    height:100%;
    
    box-sizing: border-box;
    border-color: rgba(255, 255, 255, .3);
    border-style: dashed;
    border-width: 2px;
    /* margin:5px; */
    border-radius: 16px;
    overflow:hidden;
    transition: .2s;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .1);
    cursor:pointer;

    
}
.FilePickerDiv:hover{
    /* background-color: blue; */
    /* border-style: dashed; */
    border-color: rgba(255, 255, 255, .7);
    box-shadow: 4px 8px 6px 0px rgba(0, 0, 0, .05);
}
.FilePickerDiv:active{
    /* background-color: blue; */
    /* border-style: red; */
    /* border-style: solid; */
    border-color: white;
    transition: .0s;

}

.PickImageText{
    color:white;
    /* margin:auto; */
    left:38px;
    position: absolute;
    top:0px;
    font-size:20px;
    
}