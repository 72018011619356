.TimeLineViewport{
    position: absolute;
    width:100%;
    height:100%;
    /* padding: 100px 0px 50px 0px; */
    /* box-sizing: border-box; */
    /* background: linear-gradient(rgb(219, 216, 211), rgb(187, 181, 172)); */
    background: linear-gradient(rgb(172, 169, 165), rgb(139, 135, 128));
    background-size: 100px 100px;

    
    /* background-clip: unset; */
    /* background-position:  0px 0px; */
    /* background-position-x:  */
    background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.08) 1px, transparent 1px), 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 1px, transparent 1px);
}
