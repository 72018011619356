.TopLeftArea{
    position: absolute;

    top:0px;
    left:20px;
    width:calc(100% - (160px + 70px));
    height:160px;
    /* padding:10px; */
    box-sizing: border-box;
    /* background-color: orange; */
}
.TopRightArea{
    position: absolute;

    top:0px;
    right:30px;
    /* left:60%; */
    width:160px;
    height:160px;
    box-sizing: border-box;
    /* padding:5px; */
    /* background-color: purple; */
}
.BottomArea{
    position: absolute;
    bottom:10px;
    right:10px;
    left:10px;
    height:calc(100% - (160px + 10px));
    box-sizing: border-box;
    /* background-color: green; */
}


.CreateNewCharacter {

    overflow: hidden;
    max-height: 300px;
    width:100%;
    height:100%;

}
#windowTitle {
    margin:auto;
    width:300px;
    font-size: 40px;
    color:white;
}
#imageDiv{
    position: absolute;
    width:100px;
    height:100px;
    right:190px;
    top:80px;
    /* background-image: 'aaa'; */
    /* background-color: red; */


    /* border-radius: 4px; */


}
#itemImage{
    object-fit: cover;
    width:250px;
    height:250px;
    border-radius: 6px;
    border-style: solid;
    border-color: rgba(255, 255, 255, .5);
    /* max-width:100%;
    max-height:100%; */
    /* background-image: 'aaa'; */
    border-color: rgba(0, 0, 0, .1);
    border-radius: 14px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
}
/* 
.Button
{
    position: absolute;
    margin:auto;
    width:300px;
    font-size: 30px;
    color:white;

    background-color: rgba(255, 255, 255, .2);
    border-color: rgba(0, 0, 0, .1);
    border-radius: 5px;
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05);
    transition: .2s;
    transition-property: background-color, box-shadow;

}


.Button:hover {
    transition-property: background-color, box-shadow;
    background-color: rgba(255, 255, 255, .6);
    transition: .2s;
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);

}
.Button:focus { 
    background-color: rgba(255, 255, 255, .8);
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, .05);
} */

.UploadArea {
    position: absolute;
    top: 60px;
    width:100%;
    /* height:100%; */
    padding: 20px;
    box-sizing: border-box;
    font-size: 40px;
    color: white;
    /* margin:10px; */
    /* background-color: rgba(0, 0, 0, .5); */
}