.ImageDiv{
    /* position: absolute;
    left:200px; */
    /* height:200px;
    width:200px; */
    /* position: absolute; */
    /* top:20px; */
    height:100%;
    width:100%;
    overflow:hidden;
    /* border-style: dashed; */
    /* border-color: grey; */
    /* border-radius: 10px; */
    /* border-width: 3px; */
    /* background-color: grey; */
    /* padding:10px; */
    box-sizing: border-box;
}

/* .ImageDiv:hover{
    border-style: dashed;
    border-color: white;
}
.ImageDiv:active{

    border-color: black;
} */
.ImageIMG{
    position: relative;
    left:0px;
            /*

    right:0px;

    top:0px;
    height:200px;
    width:200px; */
    /* object-fit: contain; */
    object-fit: cover;
    height:100%;
    width:100%;
    font-size:15px;
    color:black;
    

}
.ImageIcon{
    /* position: absolute; */
    left:0px;
            /*

    right:0px;

    top:0px;
    height:200px;
    width:200px; */
    /* object-fit: contain; */
    /* margin-top:10px; */
    object-fit: cover;
    height:100%;
    width:100%;
    color:white;
    opacity: .5;
    font-size:180px;
    

}
/* .ImageIMG.hover{
    background-color: blue;
} */

.ImageProgressDarken{
    position: absolute;
    right:0px;
    height:100%;
    width:100%;
    /* background-color: rgba(255,255,255,.66); */
    /* background-color: rgba(0,0,0,.66); */
    background-color: grey;
    opacity:.8;
    /* border-style:  none none none dashed; */
    /* border-color: white;
    border-width: 3px; */
    transition:1s;

}

.ImageUpdloadText{
    position: absolute;
    margin:auto;
    left:0px;
    right:0px;
    width:160px;
    bottom:10px;
    color:white;
    font-size:16px;
    /* font-weight: bold; */
    background-color:rgba(0,0,0,.5);
    border-radius: 6px;
    opacity:1;
    transition:1s;
    transition-delay: 2s;

}