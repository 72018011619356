.ViewportHudDiv{
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    pointer-events: none;
    transition: bottom .2s;

}

.TimeLineDiv{
    pointer-events: all;
}
.buttonHoverLabel{
    position: relative;
    right:70px;
    top:-30px;
    opacity: 0.7;
    font-size: 26px;
    color: white;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: bold;


}