.TimeControlKeyDiv{
    /* left:calc(40% - 4px); */
    bottom:0px;
    /* bottom:100px; */
    position: absolute;
    background-color: orange;
    /* opacity: .6; */
    border-radius: 4px 4px 0px 0px;
    width:6px;
    height:60%;
    /* border-style: solid; */
    border-color: red;
    border-width: 1px;
    box-shadow: -1px 2px 2px 1px rgba(0, 0, 0, .2);
    cursor: pointer;
    pointer-events: all;

}
.TimeControlKeyDiv_dragging{
    /* background-color: rgb(255, 217, 146); */
    /* cursor: e-resize; */
    cursor: unset;
}
.TimeControlKeyDiv_notDragging:hover{
    background-color: rgb(255, 217, 146);
    /* box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, .1); */
}
.TimeControlKeyDiv_notDragging:active{
    background-color: white;
    /* box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, .1); */
}

.KeyAttrHover{
    /* box-shadow: 0px 0px 8px 2px rgba(255, 189, 67, 0.5); */
    box-shadow: 0px 0px 5px 0px rgba(255, 251, 244, 0.5);
    background-color: rgb(255, 189, 67);

}
/* .KeyAttrNotHover{ */
    /* box-shadow: 0px 0px 8px 2px rgba(255, 189, 67, 0.5);
    box-shadow: 0px 0px 4px 2px rgba(255, 251, 244, 0.5);
    background-color: rgb(255, 189, 67); */
    /* opacity:.5; */
    /* background-color: rgb(192, 125, 0); */


/* } */

.KeyAttrSelected{
    /* box-shadow: 0px 0px 8px 2px rgba(255, 189, 67, 0.5); */
    background-color: white;

}
.KeyAttrSelected:hover{
    background-color: white;
}