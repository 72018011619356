.FrameDiv{
    /* position: relative; */
    /* font-family: 'Open Sans Condensed', sans-serif;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1); */
    display: block;
}
.FrameLabel{
    position: relative;
    font-family: 'Open Sans Condensed', sans-serif;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, .1);   
    margin:10px 0px 0px 10px;
    /* height: 15px; */
}

.FrameBorderDiv{
    position: relative;
    left:0px;
    top:0px;
    right:0px;

    border-color: rgba(255,255,255,.35);
    border-style: solid;
    border-radius: 6px;
    display: flex;
    padding:4px;
    box-sizing: border-box;

    display: block;


}