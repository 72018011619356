.ProjectCard {
    position: relative;
    width:280px;
    height:100px;
    /* background-color: rgb(130, 200, 255); */
    background-color: hsl(200, 40%, 30%);
    /* background-color: hsl(200, 36%, 38%); */
    
    border-radius: 5px;

    box-sizing: border-box;
    padding: 33px  2px;
    text-align: center;
    margin: 10px 10px;
    box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, .5);

}
.ProjectCard:hover {
    background-color: hsl(200, 60%, 35%);
    transform: translate(0px, -1px);
    box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, .3);

}

.ProjectCardEmpty {
    width:280px;
    height:100px;
    padding: 30px;
    margin: 10px 10px;

    background-color: rgba(255, 255, 255, .3);
    color: hsl(200, 60%, 25%);  

    border-radius: 5px;
    border-color: hsl(200, 60%, 25%);
    border-width: 3px;
    box-sizing: border-box;
    border-style: dashed;

    font-family: 'Rokkitt', serif;
    text-align: center;
    font-size: 22px;

    opacity:.6;

    /* box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, .3); */


    
}
.ProjectCardEmpty:hover {
    background-color: rgba(255, 255, 255, .5);
    /* border-color: rgba(255, 255, 255, 1); */
    opacity:1;


}

.ProjectCardTitle {
    font-family: 'Rokkitt', serif;

    /* margin:auto; */
    text-align: center;
    /* width:80%; */
    /* user-select: none;  */
    font-size: 26px;
    /* padding: 4px; */
    /* margin:4px; */
    color: white;
    background-color: rgba(0,0,0,0);
    border-style: none;


    outline: none;     
    pointer-events: none;
    border-radius: 5px;
   

}


#projOptionsButtonX{
    width:30px;
    height:30px;
    /* background-color: rgba(255, 255, 255, 0.2); */
    position: absolute;
    right:4px;
    top:4px;
    color: white;
    opacity: .3;
    padding:6px 0px;
    box-sizing: border-box;
    border-radius: 5px;

}

#projOptionsButtonX:hover{
    opacity: .7;
}
#projOptionsButtonX:active{
    opacity: 1;
}