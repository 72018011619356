
.TimelinePanelDiv{
    position: absolute;
    width:100%;
    height:100%;
    /* padding: 100px 0px 50px 0px; */
    /* box-sizing: border-box; */
    /* background: linear-gradient(rgb(219, 216, 211), rgb(187, 181, 172)); */
    /* background: linear-gradient(rgb(172, 169, 165), rgb(139, 135, 128)); */
    background-size: 100px 128px;

    
    /* background-clip: unset; */
    /* background-position:  0px 0px; */
    /* background-position-x:  */
    /* background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.08) 1px, transparent 1px), 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 80px, transparent 80px); */

    background-image:
    linear-gradient(to right, 
    rgba(0, 0, 0, 0.08) 1px, 
    transparent 1px
/* 
    transparent 20px,
     purple 20px, 
     purple 21px, 
     transparent 21px
     
     transparent 40px,
      purple 40px, 
      purple 41px, 
      transparent 41px,
     
      transparent 60px,
       purple 60px, 
       purple 61px, 
       transparent 61px,
      
       transparent 80px,
        purple 80px, 
        purple 81px, 
        transparent 81px */


     ), 

     
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 64px, transparent 64px); 

    /* repeating-linear-gradient(blue, blue 75px, red 75px, red 150px) */
    /* background-image: 
    linear-gradient(to right, rgba(0, 0, 255, 0.08) 3px, transparent 1px), 
    linear-gradient(to bottom, rgba(0, 0, 255, 0.08) 3px, transparent 1px); */
    /* overflow:auto; */


}
.Viewport {
    transform-origin: 0% 0%;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    /* background-color: red; */
    /* position: fixed; */
    position: absolute;
    /* transform: scale(20, 5), */
    /* transform: rotate(0deg)  scale(3, 3); */
    /* overflow:auto; */

}

.StartOutOfRange{
    position: absolute;
    left:0px;
    width:1000px;
    transform: translateX(-1000px);
    height:100%;
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}
.EndOutOfRange{
    position: absolute;
    left:0px;
    width:1000px;
    /* transform: translateX(-1000px); */
    height:100%;
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}

.ZeroLine{

    position: absolute;
    width:100%;
    height:4px;
    /* top:-200px; */
    background-color: rgb(160, 160, 160);
}


.timeTicks{
    /* background-color: orange; */
    opacity: .05;
    position: absolute;
    /* left:-10px; */
    /* right:1px; */
    /* height:20px; */
    top:0px;
    bottom:0px;
    pointer-events: none;
    
    background-size: 1000px 128px;
    background-image:
    linear-gradient(to right, 
    black 4px, 
    transparent 1px

    )
    
}