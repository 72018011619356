.uber-column-item-top-div{
    /* border-style: solid; */
    /* border-radius: 5px; */
    /* background-color: rgba(rgba(192, 136, 136, 0.774)); */
    /* opacity: 0; */
    box-sizing: border-box;
    /* height: 300px; */
    /* width:300px;  */
    position: relative  ;
    user-select: none; 
    /* color: white  ; */
    padding:10px 30px;
    /* padding:0px; */
    /* margin:0px; */
    transition: .07s;

}

.uber-column-item-grab{
    /* background-color: rgba(255, 0, 0, 0.1); */
    position: absolute;
    top:12px;
    right:32px;
    width:42px;
    height:38px;
    /* height:100%; */
    /* right:0px; */
    color:black;
    font-size: 20px;
    border-radius: 4px;
    cursor: grabbing;
    /* cursor:row-resize; */
}
.uber-column-item-grab:hover{
    background-color: rgba(255, 255, 255, 0.1);

}
.uber-column-item-grab:active{
    background-color: rgba(255, 255, 255, 0.18);

}

.uber-column-item-grab-line-a{
    position: relative;
    margin:auto;
    width: 60%;
    height:2px;
    right:0px;
    top:14px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
}
.uber-column-item-grab-line-b{
    position: relative;
    margin:auto;

    width: 60%;
    height:2px;
    right:0px;
    top:20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;

}


.uber-column-item-container{
    position: relative  ;
    /* margin:0px;
    padding:0px; */

    /* right:50px; */
    /* left:0px; */

    /* width:calc(100% - 20px);     */
    /* height:20px; */
    /* background-color: blue; */

}


.column_bbox_vis{
    position: fixed;
    /* outline-style: dotted;
    outline-color: white; */
    /* position: fixed;
    left: 300px;
    top: 300px;
    width:1000px;
    height:700px; */
    /* background-color: rgba(218, 102, 102, 0.25); */
    box-sizing: border-box;
    border-style: solid;
    border-color: springgreen;
    pointer-events: none;
  }