.GuidePopupText{
    position: relative;
    font-size:20px;
    /* font-size:18px; */
    /* line-height: 90%; */

    margin:12px 30px;
    /* padding:20px; */
    box-sizing: border-box;
    /* white-space: nowrap; */
    display:inline-block;
    /* float:left; */
    /* white-space:normal; */
    /* text-align: left; */
    /* font-family: 'Open Sans Condensed', sans-serif; */
    font-family: 'Verdana', sans-serif;
    font-family: 'Roboto Condensed', sans-serif;
    font-family: 'Open Sans Condensed', sans-serif;
    font-family: 'Josefin Sans', sans-serif;

    font-weight: bold;
    color: rgba(0,0,0,.6);

}