/* .StoryItem {
    margin: 3px;
    width: 55px;
    padding: 3px 0 0 0;
    overflow: hidden;
    pointer-events: all;

} */



.ThumbnailImage {
    object-fit: cover;
    width:100%;
    height:100%;
    border-radius: 5px;
    /* padding:12px; */    
}

.StoryItem {
    position: relative;
    /* margin: 1px; */
    width: 58px;
    padding: 4px 0px 0px 0px;
    /* border-style: 'none'; */
    border-radius: '10px';
    border-color: 'white';
    border-radius:8px;
    /* border-width: '1px'; */
    min-height: 50px;
    cursor:pointer;
    
}

/* .StoryItem:hover {
    background-color: rgba(255, 255, 255, .1);
    transition: .2s;
    box-shadow: 2px 8px 8px 0px rgba(0, 0, 0, .05);

} */

/* .StoryItem:active {
    transition: 0s;
    background-color: rgba(255, 255, 255, 0.4);

} */


.StoryItemLarge {
    /* margin: 3px; */
    width: 175px;
    /* background-color: rgba(53, 147, 255, 0.3); */

    
}

.StoryItemIcon {
    text-align: center;
    /* position: fixed; */
    /* left: 400px;
    top: 300px; */
    margin: auto;
    width:40px;
    height:40px;
    /* size:3x; */
    font-size: 24px;
    /* z-index: 20; */
    /* border-color: red; */
    /* border-width: 3px; */
    background-color: rgba(255, 255, 255, .3);
    color: white;   
    /* border: 2px solid rgba(78, 78, 78, 0.2); */
    border-radius: 10px;
    /* border-style: solid; */
    /* padding-top: 4px; */
    /* margin-left: 10px;
    margin-right: 13px; */
    /* margin-top: 5px; */
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, .05);
    transition: .2s;
    /* cursor:pointer; */
    /* border-style: solid; */

    overflow: hidden;
    /* pointer-events: all; */


}

.StoryItemIconLarge {
    text-align: center;
    margin: auto;
    width:160px;
    height:160px;
    font-size: 110px;
    background-color: rgba(255, 255, 255, .3);
    color: white;   
    border-radius: 10px;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, .05);
    transition: .2s;
    /* cursor:pointer; */
    /* pointer-events: all; */

}
/* 
.StoryItemIcon:hover {
    background-color: rgba(255, 255, 255, .5);
    transition: .2s;
    box-shadow: 2px 8px 8px 0px rgba(0, 0, 0, .05);

}
.StoryItemIcon:active {
    transition: 0s;
    background-color: rgba(255, 255, 255, 0.8);

} */


.StoryItemLabel {
    /* font-family: 'Rokkitt', serif; */
    /* position: absolute; */
    background-color: rgba(255, 255, 255, 0.2); 
    border-radius: 5px;
    padding: 0px 1px;
    font-size: 12px;
    max-width: 62px;
    word-wrap: break-word;
    margin-top:2px;
    /* height:16px; */

    /* border-style: solid; */
    

    }


.StoryItemEmpty {
    text-align: center;

    margin: auto;
    width:38px;
    height:38px;
    font-size: 32px;
    background-color: rgba(255, 255, 255, .1);
    color: white;   
    border: 2px solid rgba(255, 255, 255, .6);
    border-radius: 10px;
    border-style: dashed;
    transition: .2s;
    /* cursor:pointer; */
    font-family: Arial, Helvetica, sans-serif;
    /* pointer-events: all; */

}
.StoryItemEmptyLarge {
    text-align: center;
    margin: auto;
    width:160px;
    height:160px;
    font-size: 128px;
    background-color: rgba(255, 255, 255, .1);
    color: white;   
    border: 2px solid rgba(255, 255, 255, .6);
    border-radius: 10px;
    border-style: dotted;
    transition: .2s;
    /* cursor:pointer; */
    font-family: Arial, Helvetica, sans-serif;
    /* pointer-events: all; */

}



#StoryItem-DocumentIcon {
    position: absolute;
    top:2px;
    left:12px;
    font-size: 20px;
    color:white;
}

#StoryItem-DocumentIconShad {
    position: absolute;
    top:6px;
    left:8px;
    font-size: 20px;
    color:black;
    opacity:.1;
}



/* .StoryItemEmpty:hover {
    background-color: rgba(255, 255, 255, .6);
    transition: .2s;
    font-size: 32px;
    padding-top: 0px;

} */
