
.TextEditor {
    padding: 48px 60px 0px 60px;
    box-sizing: border-box;
    height:100%;
    resize: none;
    min-width: 720px;
    max-width: 720px;
    min-height: 100%;
    max-height: 100%;
    margin: 0 auto; 
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .2);
    font-family:Arial, Helvetica, sans-serif ;
    border-style: none;
    font-size: 15px;
    outline-style: none;


}
.TextEditor:focus {
    box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, .3);

}


/* width */
.TextEditor::-webkit-scrollbar {
    width: 14px;

  
  } 
  
  /* Track */
  .TextEditor::-webkit-scrollbar-track {
    background: rgba(0,0,0,.1); 
    border-radius: 0px;

  }
  
  .TextEditor::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 12px rgba(0, 0, 0, .12); 
  } 
  
  /* Handle */
  .TextEditor::-webkit-scrollbar-thumb {
    width:3px;
    border-radius: 4px;
    background: hsla(200, 0%, 100%, .4) ;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,.1); 
  }
  .TextEditor::-webkit-scrollbar-thumb:hover {
    background: hsla(200, 0%, 100%, .6) ;
 
  }