.ColourSwatchesContainerDiv {
    position: absolute;
    bottom:20px;
    left:200px;
    /* width:100%;
    height:100%; */
    background-color: rgba(0, 0, 0, 0.03);
    border-color: rgba(0, 0, 0, 0);
    border-radius: 6px;
    border-style: solid; 
    box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, .15);
    transition: flex-basis 500ms ease-in-out;
    overflow: hidden;
    z-index: 2000;
  }


.ColourSwatchesContainerDiv-flex{
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    margin: auto;
    overflow: hidden;
    padding: 2px 2px;

    transition: flex-basis 500ms ease-in-out;
    /* background-color: palevioletred; */

}

.ColourSwatch{
    position: relative;
    width: 40px;
    height: 30px;
    background-color: orange;
    border-radius: 4px;
    margin: 4px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    /* box-sizing: content-box; */

}

.ColourSwatch:hover{
    border-color: rgba(255,255,255,.7);
    background-color: rgba(255, 255, 255, .1);
    transition: .2s;
    box-shadow: 2px 8px 8px 0px rgba(0, 0, 0, .05);
    

}

/* .StoryItem:hover {
    background-color: rgba(255, 255, 255, .1);
    transition: .2s;
    box-shadow: 2px 8px 8px 0px rgba(0, 0, 0, .05);

} */