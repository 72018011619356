
.timeControlsToggle{
    position: absolute;
    left:10px;
    right:10px;
    bottom:24px;
    /* background-color: orange; */
    border-radius: 6px;
    text-align: center;
    height: 30px;
    padding-top: 2px;
    box-sizing: border-box;
    font-family: 'Open Sans Condensed', sans-serif;
    color: rgba(22, 37, 43, 0.6);
    font-size: 18px;
    border-style: solid;
    border-color:  rgba(22, 37, 43, 0.6);
    border-width: 1px;

}

.timeControlsToggle:hover{
    
    color: hsla(200, 50%, 95%, 60%);
    border-color: hsla(200, 50%, 95%, 60%);

}


.sidenav {
    text-align: right;
    /* height: 100%; */
    width: 128px; 
    position: fixed; 
    z-index: 1; /* Stay on top */
    top: 36px;
    left: 0px;
    bottom:0px;

    /* background: linear-gradient(hsl(200, 4%, 70%) ,hsl(200, 10%, 50%)); */
    background: linear-gradient(hsl(200, 4%, 70%) ,hsl(200, 5%, 66%));
    /* background: linear-gradient(hsl(0, 0%, 15%) ,hsl(0, 0%, 9%)); */

    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px; 
    transition: 0.15s; /* 0.5 second transition effect to slide in the sidenav */
    /* word-wrap: none;   */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);


}


/* The navigation menu links */
.sidenav a {
    padding: 4px 8px 6px 8px;
    font-size: 19px;
    /* color: hsla(200, 40, 20, .6); */
    color: rgba(22, 37, 43, 0.6);
    /* color: rgba(184, 184, 184, 0.6); */
    display: block;
    transition: 0.1s;
    font-family: 'Open Sans Condensed', sans-serif;
    cursor:pointer;
    /* font-weight: 500; */
 

}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover{
    color: hsla(200, 50%, 95%, 60%);
}
.sidenav a:active {
    transition: 0s;
    color: white;

}

.closebtn{
    /* align-self: flex-end; */
      text-align: right;
      cursor:pointer;


}

#big_icons{
    transition: margin-left .5s;
    /* font-family: 'Suranna', serif; */

    /* word-wrap: none;   
    justify-items: left;
    align-self: flex-start; */
    /* justify-items: start; */
    /* padding-right: 30px; */
}
#small_icons{
    transition: margin-left .5s;
    /* word-wrap: none;    */
}
