:root {
    /* --foldout-bg-color:  rgb(235, 235, 235); */
    --foldout-bg-color:  rgba(235, 235, 235, .3);
    --foldout-bg-color-b:  rgba(235, 235, 235, 1);
    --foldout-bg-color-hover:  rgba(235, 235, 235, .4);
    --foldout-bg-color-dark:  rgba(0, 0, 0, 1);
    --foldout-bg-color-dark-hover:  rgba(0, 0, 0, 0.4);
  }


.FoldoutPanelDiv{
    position: fixed;
    background-color:var(--foldout-bg-color);
    /* background-color: rgba(235, 235, 235, 0.2); */
    /* background-color: rgba(185, 190, 192,1);  */

    /* height: 200px;  */
    border-radius:4px;
    /* box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, .3); */
    /* box-sizing: border-box; */
    z-index:1000;

    /* color: rgb(240, 240, 240); */
    /* opacity: 1; */
    /* filter: blur(20px); */
    /* opacity:0; */
    animation-name: scale_bottom;
    animation-duration: .2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    /* background-color: purple;  */

    /* background: linear-gradient(to top right, hsla(200, 4%, 70%, .3) ,rgba(3, 13, 17, 0.3)); */
    background: linear-gradient(to top, hsla(200, 4%, 85%, .6) ,hsla(200, 4%, 100%, .6));
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .3);
    box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, .3);
    border-style: none;
    border-radius: 10px;
    backdrop-filter: blur(3px);
    /* padding:20px; */
    /* padding:20px; */
    box-sizing: border-box;
    /* overflow:auto; */
    /* float:left; */
    pointer-events: fill;

}
.FoldoutPanelDiv-hover:hover{
    background-color:var(--foldout-bg-color-hover);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, .5);

}

.FoldoutPanelBG{
    position:fixed;
    left:0px;
    top:0px;
    height: 100%;
    width: 100%;
    /* background-color: rgba(0, 0, 0, .4 ); */
    /* background-color: rgb(249, 52, 137); */
    z-index:10000;
    animation-name: bgDark;
    animation-duration: .13s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

}

.foldoutDarkMode{
    /* background-color:var(--foldout-bg-color-dark); */
    background-color:white;
    /* color: white; */
    /* background: linear-gradient(to top, rgba(16, 24, 39, 0.6) ,rgba(21, 50, 65, 0.6)); */
    /* color: rgba(0, 0, 0, 0.8); */

}
.foldoutDarkMode:hover{
    background-color:white;

    /* background-color:var(--foldout-bg-color-dark-hover); */
    /* color: rgba(255, 255, 255, 0.6); */

}
@keyframes bgDark {
    0% {
        background-color: rgba(0, 0, 0, 0 );
    }
    100% {
        background-color: rgba(0, 0, 0, .4 );
        background-color: rgba(0, 0, 0, .2 );
    }
  }

  @keyframes scale_left {
    0% {
        opacity: 0;
        transform: scaleX(0.9) translateX(20px);;
        transform-origin: right;
    }
    100% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: right;
    }
  }
  @keyframes scale_right {
    0% {
        opacity: 0;
        transform: scaleX(0.9) translateX(-20px);
        transform-origin: left;
    }
    100% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
    }
  }
  @keyframes scale_top {
    0% {
        opacity: 0;
        transform: scaleY(0.9) translateY(20px);
        transform-origin: bottom;
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: bottom;
    }
  }
  @keyframes scale_bottom {
    0% {
        opacity: 0;
        transform: scaleY(0.9) translateY(-20px);
        transform-origin: top;
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: top;
    }
  }
  @keyframes scale_center {
    0% {
        
        opacity: 0;
        transform: scale(.5) translateY(20px);
        transform-origin: center;
    }
    100% {
        opacity: 1;
        transform:  translateY(0px);
        transform-origin: center;
    }
  }
  @keyframes scale_center_top {
    0% {
        
        opacity: 0;
        transform: scale(.5) translateY(-20px);
        transform-origin: center;
    }
    100% {
        opacity: 1;
        transform:  translateY(0px);
        transform-origin: center;
    }
  }



.FoldoutArrowUp {
    position:fixed;
    /* top:42px;
    right:812px; */
    width: 0; 
    height: 0; 
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid var(--foldout-bg-color-b);
    /* border-bottom: 14px solid white; */
    /* border-bottom: 14px solid rgb(255, 17, 17);; */
    /* z-index:2000; */

}
.FoldoutArrowDown {
    position:fixed;
    /* top:42px;
    right:812px; */
    width: 0; 
    height: 0; 
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    /* border-top: 14px solid var(--foldout-bg-color); */
    border-top: 14px solid var(--foldout-bg-color-b);
    z-index:1001;

}
.FoldoutArrowLeft {
    /* border-color: rgba(230, 230, 230, .5); */

    position:fixed;
    /* top:42px;
    right:812px; */
    width: 0; 
    height: 0; 
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    /* border-right: 14px solid  rgb(230, 230, 230); */
    border-right: 14px solid  var(--foldout-bg-color-b);
    z-index:1001;

}
/* .FoldoutArrowRight {
    position:fixed;
    width: 0; 
    height: 0; 
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 14px solid var(--foldout-bg-color);
    z-index:1001;
} */
.FoldoutArrowRight {
    position:fixed;
    /* top:42px;
    right:812px; */
    width: 0; 
    height: 0; 
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 14px solid var(--foldout-bg-color-b);
    /* border-left: 14px solid white; */
    z-index:1001;
}



.testBBoxDiv{
    position:fixed;
    background-color: rgba(235, 43, 235, 0.3);
    z-index:1000;
}

.testFoldoutChildDiv{
    position:absolute;
    width:100%;
    height:100%;
    background-color: rgba(129, 235, 43, .1);
    z-index:1001;

    padding:20px;
    box-sizing: border-box;

}



.FoldoutListItem {
    display:flex;
    text-align: right;
    position: relative;
    height:34px;
    margin:6px;
    box-sizing: border-box;
    /* font-family:Arial, Helvetica, sans-serif ; */
    font-size: 16px;
    pointer-events: all;
    padding:0px 10px ;
    border-radius: 4px;
    background-color: rgb(250, 250, 250); 
    /* background-color: rgba(185, 190, 192,.5);  */
    /* box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, .05); */
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 24px;
    /* color:white; */

}



.FoldoutListItem:hover {
    /* opacity: 1; */
    /* color:greenyellow;  */

    background-color: rgb(241, 241, 241); 
    background-color: rgb(224, 224, 224); 


}
